import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        backgroundColor: '#f4f6f8',
        height: '100%',
        width: '100%',
      },
      a: {
        textDecoration: 'none',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      // Hamburger icon and navigation icons
      svg: {
        color: '#b0b0b4 !important',
      },
      // SCroll bar
      '*::-webkit-scrollbar': {
        width: '5px',
      },
      '*::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px #4c4c4c',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#4c4c4c',
        borderRadius: '20px !important',
      },

      // Buttons
      '.MuiButton-containedPrimary': {
        backgroundColor: '#71904a !important',
        color: 'white !important',
        borderRadius: '10px',
      },
    },
  }),
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
