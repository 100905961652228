import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  InputLabel,
  Breadcrumbs,
  MenuItem,
  Typography,
  Avatar,
  Card,
  TextField,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import adminService from '../../services/adminService';
import { consoleError, getImageUrl } from '../../utils/helper';
import * as yup from 'yup';
// import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from '../../components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import CustomFileUpload from '../../components/CustomFileUpload';
import CustomFileUploadServer from '../../components/CustomFileUploadServer';
import { Editor } from '@tinymce/tinymce-react';
import config from '../../config';
import api from 'src/services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    height: 400,
  },
  textArea: {
    width: '100%',
    height: 100,
  },
}));
function EditAbout() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [id, setId] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);

  const match = useMatch('/about/:id/edit');
  useEffect(() => {
    if (match && match.params && match.params.id) {
      setId(match.params.id);
      setUpdate(true);
    }
  }, [match]);

  const aboutus = useSelector((state) => state.admin.aboutus);
  const abotUs = aboutus.find((Item) => Item._id.toString() === id);

  console.log({ aboutus, abotUs, id });

  useEffect(() => {
    async function getAboutUs() {
      try {
        adminService.getAboutUs(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getAboutUs();
  }, []);

  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [tempImage, setTempImage] = useState();

  const [bottomImage, setBottomImage] = useState('');
  const [tempImage2, setTempImage2] = useState();

  useEffect(() => {
    if (abotUs) {
      setTitle(abotUs?.title);
      setImage(abotUs?.image);
    }
  }, [abotUs]);

  const editorRef1 = useRef(abotUs ? abotUs.desc1 : null);

  const editorRef2 = useRef(abotUs ? abotUs.desc2 : null);

  const uploadImageHandler = async (e, values, setFieldValue) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const image_New_Name = res.data;
      setTempImage(res.data);
      // values.image = image_New_Name;
      setImage(image_New_Name);
      console.log(values);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadImageHandler2 = async (e, values, setFieldValue) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const image_New_Name = res.data;
      setTempImage2(res.data);
      // values.image = image_New_Name;
      setBottomImage(image_New_Name);
      console.log(values);
    } catch (error) {
      console.error(error);
    }
  };

  const submitHandler = async () => {
    if (update) {
      try {
        let res = await adminService.updateAboutUs(
          id,
          {
            title: title,
            image: image,
            bottomImage: bottomImage,
            desc1: editorRef1.current.getContent(),
            desc2: editorRef2.current.getContent(),
          },
          dispatch,
        );
        if (res && res.ok) {
          setSuccessMsg(res.message || 'AboutUs updated successfully.');
        }
      } catch (error) {
        consoleError(error);
        setErrMsg(error.message || 'Error updating AboutUs. Please try again');
      }
    } else {
      try {
        let res = await adminService.addAboutUs(
          {
            title: title,
            image: image,
            bottomImage: bottomImage,
            desc1: editorRef1.current.getContent(),
            desc2: editorRef2.current.getContent(),
          },
          dispatch,
        );
        if (res && res.ok) {
          setSuccessMsg(res.message || 'AboutUs added successfully.');
        }
      } catch (error) {
        consoleError(error);
        setErrMsg(error.message || 'Error adding AboutUs. Please try again');
      }
    }
  };

  return (
    <Page
      className={classes.root}
      title={update ? 'Update About Us' : 'Add About Us'}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2}>
            <Typography variant="h2">
              {update ? 'Update About Us' : 'Add About Us'}
            </Typography>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/about">
                About Us
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>

          <Card className={classes.editorCard}>
            <Grid
              container
              alignItems="flex-start"
              justify="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  value={title}
                  fullWidth
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <input
                  type="file"
                  name="image"
                  onChange={(e) => uploadImageHandler(e)}
                  accept="image/*"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {image ? (
                  <img
                    src={`${config.apiUrl}${config.imageUrlLocal}/images/${image}`}
                    alt="banner"
                    style={{ width: '200px', height: '200px' }}
                  />
                ) : tempImage ? (
                  <img
                    src={`${config.apiUrl}${config.imageUrlLocal}/images/${tempImage}`}
                    alt="banner"
                    style={{ width: '200px', height: '200px' }}
                  />
                ) : (
                  ''
                )}
              </Grid>

              <Grid item xs={12}>
                <Editor
                  onInit={(evt, editor) => (editorRef1.current = editor)}
                  initialValue={update ? abotUs.desc1 : ''}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Editor
                  onInit={(evt, editor) => (editorRef2.current = editor)}
                  initialValue={update ? abotUs.desc2 : ''}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <input
                  type="file"
                  name="bottomImage"
                  onChange={(e) => uploadImageHandler2(e)}
                  accept="image/*"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {bottomImage ? (
                  <img
                    src={`${config.apiUrl}${config.imageUrlLocal}/images/${bottomImage}`}
                    alt="banner"
                    style={{ width: '200px', height: '200px' }}
                  />
                ) : tempImage2 ? (
                  <img
                    src={`${config.apiUrl}${config.imageUrlLocal}/images/${tempImage2}`}
                    alt="banner"
                    style={{ width: '200px', height: '200px' }}
                  />
                ) : (
                  ''
                )}
              </Grid>
              <Box mt={2}></Box>
            </Grid>
            <Box mt={1} mb={1}>
              {errMsg && (
                <Alert severity="error" onClose={() => setErrMsg('')}>
                  <AlertTitle>Error</AlertTitle>
                  {errMsg}
                </Alert>
              )}
              {successMsg && (
                <Alert severity="success" onClose={() => setSuccessMsg('')}>
                  <AlertTitle>Success</AlertTitle>
                  {successMsg}
                </Alert>
              )}
            </Box>
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                onClick={submitHandler}
              >
                {update ? 'Update data' : 'Add data'}
              </Button>
            </Box>
          </Card>
        </Container>
      </Box>
    </Page>
  );
}

export default EditAbout;
