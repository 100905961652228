// USERS
export const ADMIN_GET_USERS = 'ADMIN_GET_USERS';
export const ADMIN_ADD_USER = 'ADMIN_ADD_USER';
export const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER';
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER';

export function adminGetUsers(payload) {
  return { type: ADMIN_GET_USERS, payload };
}
export function adminAddUser(payload) {
  return { type: ADMIN_ADD_USER, payload };
}
export function adminUpdateUser(payload) {
  return { type: ADMIN_UPDATE_USER, payload };
}
export function adminDeleteUser(payload) {
  return { type: ADMIN_DELETE_USER, payload };
}

// SITE SETTING
export const ADMIN_GET_SITE_SETTING = 'ADMIN_GET_SITE_SETTING';
export const ADMIN_UPDATE_SITE_SETTING = 'ADMIN_UPDATE_SITE_SETTING';

export function adminGetSiteSetting(payload) {
  return { type: ADMIN_GET_SITE_SETTING, payload };
}
export function adminUpdateSiteSetting(payload) {
  return { type: ADMIN_UPDATE_SITE_SETTING, payload };
}

// BANNER
export const ADMIN_GET_BANNER = 'ADMIN_GET_BANNER';
export const ADMIN_ADD_BANNER = 'ADMIN_ADD_BANNER';
export const ADMIN_UPDATE_BANNER = 'ADMIN_UPDATE_BANNER';
export const ADMIN_DELETE_BANNER = 'ADMIN_DELETE_BANNER';

export function adminGetBanner(payload) {
  return { type: ADMIN_GET_BANNER, payload };
}
export function adminAddBanner(payload) {
  return { type: ADMIN_ADD_BANNER, payload };
}
export function adminUpdateBanner(payload) {
  return { type: ADMIN_UPDATE_BANNER, payload };
}
export function adminDeleteBanner(payload) {
  return { type: ADMIN_DELETE_BANNER, payload };
}

// STATS
export const ADMIN_GET_STAT = 'ADMIN_GET_STAT';
export const ADMIN_ADD_STAT = 'ADMIN_ADD_STAT';
export const ADMIN_UPDATE_STAT = 'ADMIN_UPDATE_STAT';
export const ADMIN_DELETE_STAT = 'ADMIN_DELETE_STAT';

export function adminGetStat(payload) {
  return { type: ADMIN_GET_STAT, payload };
}
export function adminAddStat(payload) {
  return { type: ADMIN_ADD_STAT, payload };
}
export function adminUpdateStat(payload) {
  return { type: ADMIN_UPDATE_STAT, payload };
}
export function adminDeleteStat(payload) {
  return { type: ADMIN_DELETE_STAT, payload };
}

// TRUSTWORTHY
export const ADMIN_GET_TRUSTWORTHY = 'ADMIN_GET_TRUSTWORTHY';
export const ADMIN_ADD_TRUSTWORTHY = 'ADMIN_ADD_TRUSTWORTHY';
export const ADMIN_UPDATE_TRUSTWORTHY = 'ADMIN_UPDATE_TRUSTWORTHY';
export const ADMIN_DELETE_TRUSTWORTHY = 'ADMIN_DELETE_TRUSTWORTHY';

export function adminGetTrustWorthy(payload) {
  return { type: ADMIN_GET_TRUSTWORTHY, payload };
}
export function adminAddTrustWorthy(payload) {
  return { type: ADMIN_ADD_TRUSTWORTHY, payload };
}
export function adminUpdateTrustWorthy(payload) {
  return { type: ADMIN_UPDATE_TRUSTWORTHY, payload };
}
export function adminDeleteTrustWorthy(payload) {
  return { type: ADMIN_DELETE_TRUSTWORTHY, payload };
}

//Contact us
export const ADMIN_GET_CONTACTUSALL = 'ADMIN_GET_CONTACTUSALL';
export const ADMIN_DELETE_CONTACTUS = 'ADMIN_DELETE_CONTACTUS';

export function adminGetContactusall(payload) {
  return { type: ADMIN_GET_CONTACTUSALL, payload };
}
export function adminDeleteContactus(payload) {
  return { type: ADMIN_DELETE_CONTACTUS, payload };
}

// NETWORKRANGE
export const ADMIN_GET_NETWORKRANGE = 'ADMIN_GET_NETWORKRANGE';
export const ADMIN_ADD_NETWORKRANGE = 'ADMIN_ADD_NETWORKRANGE';
export const ADMIN_UPDATE_NETWORKRANGE = 'ADMIN_UPDATE_NETWORKRANGE';
export const ADMIN_DELETE_NETWORKRANGE = 'ADMIN_DELETE_NETWORKRANGE';

export function adminGetNetworkRange(payload) {
  return { type: ADMIN_GET_NETWORKRANGE, payload };
}
export function adminAddNetworkRange(payload) {
  return { type: ADMIN_ADD_NETWORKRANGE, payload };
}
export function adminUpdateNetworkRange(payload) {
  return { type: ADMIN_UPDATE_NETWORKRANGE, payload };
}
export function adminDeleteNetworkRange(payload) {
  return { type: ADMIN_DELETE_NETWORKRANGE, payload };
}

// ONLINE_ORDER_SERVICES
export const ADMIN_GET_ONLINE_ORDER_SERVICES =
  'ADMIN_GET_ONLINE_ORDER_SERVICES';
export const ADMIN_ADD_ONLINE_ORDER_SERVICES =
  'ADMIN_ADD_ONLINE_ORDER_SERVICES';
export const ADMIN_UPDATE_ONLINE_ORDER_SERVICES =
  'ADMIN_UPDATE_ONLINE_ORDER_SERVICES';
export const ADMIN_DELETE_ONLINE_ORDER_SERVICES =
  'ADMIN_DELETE_ONLINE_ORDER_SERVICES';

export function adminGetOnlineOrderServices(payload) {
  return { type: ADMIN_GET_ONLINE_ORDER_SERVICES, payload };
}
export function adminAddOnlineOrderServices(payload) {
  return { type: ADMIN_ADD_ONLINE_ORDER_SERVICES, payload };
}
export function adminUpdateOnlineOrderServices(payload) {
  return { type: ADMIN_UPDATE_ONLINE_ORDER_SERVICES, payload };
}
export function adminDeleteOnlineOrderServices(payload) {
  return { type: ADMIN_DELETE_ONLINE_ORDER_SERVICES, payload };
}

// Orders
export const ADMIN_GET_ORDERS = 'ADMIN_GET_ORDERS';
export const ADMIN_UPDATE_ORDER = 'ADMIN_UPDATE_ORDER';
export const ADMIN_DELETE_ORDER = 'ADMIN_DELETE_ORDER';

export function adminGetOrders(payload) {
  return { type: ADMIN_GET_ORDERS, payload };
}
export function adminUpdateOrders(payload) {
  return { type: ADMIN_UPDATE_ORDER, payload };
}
export function adminDeleteOrder(payload) {
  return { type: ADMIN_DELETE_ORDER, payload };
}

// YYCSERVICES
export const ADMIN_GET_YYCSERVICES = 'ADMIN_GET_YYCSERVICES';
export const ADMIN_ADD_YYCSERVICES = 'ADMIN_ADD_YYCSERVICES';
export const ADMIN_UPDATE_YYCSERVICES = 'ADMIN_UPDATE_YYCSERVICES';
export const ADMIN_DELETE_YYCSERVICES = 'ADMIN_DELETE_YYCSERVICES';

export function adminGetYYCServices(payload) {
  return { type: ADMIN_GET_YYCSERVICES, payload };
}
export function adminAddYYCServices(payload) {
  return { type: ADMIN_ADD_YYCSERVICES, payload };
}
export function adminUpdateYYCServices(payload) {
  return { type: ADMIN_UPDATE_YYCSERVICES, payload };
}
export function adminDeleteYYCServices(payload) {
  return { type: ADMIN_DELETE_YYCSERVICES, payload };
}

// ABOUT US
export const ADMIN_GET_ABOUT_US = 'ADMIN_GET_ABOUT_US';
export const ADMIN_ADD_ABOUT_US = 'ADMIN_ADD_ABOUT_US';
export const ADMIN_UPDATE_ABOUT_US = 'ADMIN_UPDATE_ABOUT_US';
export const ADMIN_DELETE_ABOUT_US = 'ADMIN_DELETE_ABOUT_US';

export function adminGetAboutUs(payload) {
  return { type: ADMIN_GET_ABOUT_US, payload };
}
export function adminAddAboutUs(payload) {
  return { type: ADMIN_ADD_ABOUT_US, payload };
}
export function adminUpdateAboutUs(payload) {
  return { type: ADMIN_UPDATE_ABOUT_US, payload };
}
export function adminDeleteAboutUs(payload) {
  return { type: ADMIN_DELETE_ABOUT_US, payload };
}

//SERVICE IMAGES
export const ADMIN_GET_SERVICE_IMAGE = 'ADMIN_GET_SERVICE_IMAGE';
export const ADMIN_ADD_SERVICE_IMAGE = 'ADMIN_ADD_SERVICE_IMAGE';
export const ADMIN_UPDATE_SERVICE_IMAGE = 'ADMIN_UPDATE_SERVICE_IMAGE';
export const ADMIN_DELETE_SERVICE_IMAGE = 'ADMIN_DELETE_SERVICE_IMAGE';

export function adminGetServiceImage(payload) {
  return { type: ADMIN_GET_SERVICE_IMAGE, payload };
}
export function adminAddServiceImage(payload) {
  return { type: ADMIN_ADD_SERVICE_IMAGE, payload };
}
export function adminUpdateServiceImage(payload) {
  return { type: ADMIN_UPDATE_SERVICE_IMAGE, payload };
}
export function adminDeleteServiceImage(payload) {
  return { type: ADMIN_DELETE_SERVICE_IMAGE, payload };
}

// LOCATION
export const ADMIN_GET_LOCATION = 'ADMIN_GET_LOCATION';
export const ADMIN_ADD_LOCATION = 'ADMIN_ADD_LOCATION';
export const ADMIN_UPDATE_LOCATION = 'ADMIN_UPDATE_LOCATION';
export const ADMIN_DELETE_LOCATION = 'ADMIN_DELETE_LOCATION';

export function adminGetLocation(payload) {
  return { type: ADMIN_GET_LOCATION, payload };
}
export function adminAddLocation(payload) {
  return { type: ADMIN_ADD_LOCATION, payload };
}
export function adminUpdateLocation(payload) {
  return { type: ADMIN_UPDATE_LOCATION, payload };
}
export function adminDeleteLocation(payload) {
  return { type: ADMIN_DELETE_LOCATION, payload };
}

// PAGES
export const ADMIN_GET_PAGES = 'ADMIN_GET_PAGES';
export const ADMIN_ADD_PAGES = 'ADMIN_ADD_PAGES';
export const ADMIN_UPDATE_PAGES = 'ADMIN_UPDATE_PAGES';
export const ADMIN_DELETE_PAGES = 'ADMIN_DELETE_PAGES';

export function adminGetPages(payload) {
  return { type: ADMIN_GET_PAGES, payload };
}
export function adminAddPages(payload) {
  return { type: ADMIN_ADD_PAGES, payload };
}
export function adminUpdatePages(payload) {
  return { type: ADMIN_UPDATE_PAGES, payload };
}
export function adminDeletePages(payload) {
  return { type: ADMIN_DELETE_PAGES, payload };
}

// PARTNERS
export const ADMIN_GET_PARTNERS = 'ADMIN_GET_PARTNERS';
export const ADMIN_ADD_PARTNERS = 'ADMIN_ADD_PARTNERS';
export const ADMIN_UPDATE_PARTNERS = 'ADMIN_UPDATE_PARTNERS';
export const ADMIN_DELETE_PARTNERS = 'ADMIN_DELETE_PARTNERS';

export function adminGetPartners(payload) {
  return { type: ADMIN_GET_PARTNERS, payload };
}
export function adminAddPartners(payload) {
  return { type: ADMIN_ADD_PARTNERS, payload };
}
export function adminUpdatePartners(payload) {
  return { type: ADMIN_UPDATE_PARTNERS, payload };
}
export function adminDeletePartners(payload) {
  return { type: ADMIN_DELETE_PARTNERS, payload };
}
