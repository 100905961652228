import api from './api';

import {
  adminGetUsers,
  adminAddUser,
  adminUpdateUser,
  adminDeleteUser,
  adminGetSiteSetting,
  adminUpdateSiteSetting,
  adminGetBanner,
  adminAddBanner,
  adminUpdateBanner,
  adminDeleteBanner,
  adminGetStat,
  adminAddStat,
  adminUpdateStat,
  adminDeleteStat,
  adminGetTrustWorthy,
  adminAddTrustWorthy,
  adminUpdateTrustWorthy,
  adminDeleteTrustWorthy,
  adminGetContactusall,
  adminDeleteContactus,
  adminGetNetworkRange,
  adminAddNetworkRange,
  adminUpdateNetworkRange,
  adminDeleteNetworkRange,
  adminGetYYCServices,
  adminAddYYCServices,
  adminUpdateYYCServices,
  adminDeleteYYCServices,
  adminGetOrders,
  adminUpdateOrders,
  adminDeleteOrder,
  adminGetOnlineOrderServices,
  adminAddOnlineOrderServices,
  adminUpdateOnlineOrderServices,
  adminDeleteOnlineOrderServices,
  adminGetAboutUs,
  adminAddAboutUs,
  adminUpdateAboutUs,
  adminDeleteAboutUs,
  adminGetServiceImage,
  adminAddServiceImage,
  adminUpdateServiceImage,
  adminDeleteServiceImage,
  adminGetLocation,
  adminAddLocation,
  adminUpdateLocation,
  adminDeleteLocation,
  adminGetPages,
  adminAddPages,
  adminUpdatePages,
  adminDeletePages,
  adminGetPartners,
  adminAddPartners,
  adminUpdatePartners,
  adminDeletePartners,
} from '../store/actions/adminActions';

const adminService = {
  // USER
  getUsers: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/user');
      dispatch(adminGetUsers(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addUser: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/user/store', data);
      dispatch(adminAddUser(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateUser: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/user/update/${_id}`, data);
      dispatch(adminUpdateUser(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteUser: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/user/delete/${_id}`);
      dispatch(adminDeleteUser(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getTranslation: async (text) => {
    try {
      let res = await api.post('/api/v1/admin/translate/chinese', {
        text: text,
      });
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // BANNERS
  getBanners: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/banners');
      dispatch(adminGetBanner(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addBanner: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/banner/store', data);
      dispatch(adminAddBanner(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateBanner: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/banner/update/${_id}`, data);
      dispatch(adminUpdateBanner(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteBanner: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/banner/delete/${_id}`);
      dispatch(adminDeleteBanner(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Site Setting
  getSiteSetting: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/setting');
      dispatch(adminGetSiteSetting(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateSiteSetting: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/setting/update/${_id}`, data);
      dispatch(adminUpdateSiteSetting(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // STATS
  getStats: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/stats');
      dispatch(adminGetStat(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addStat: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/stats/store', data);
      dispatch(adminAddStat(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateStat: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/stats/update/${_id}`, data);
      dispatch(adminUpdateStat(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteStat: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/stats/delete/${_id}`);
      dispatch(adminDeleteStat(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // TRUSTWORTHY
  getTrustWorthy: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/trustworthy');
      dispatch(adminGetTrustWorthy(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addTrustWorthy: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/trustworthy/store', data);
      dispatch(adminAddTrustWorthy(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateTrustWorthy: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(
        `/api/v1/admin/trustworthy/update/${_id}`,
        data,
      );
      dispatch(adminUpdateTrustWorthy(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteTrustWorthy: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/trustworthy/delete/${_id}`);
      dispatch(adminDeleteTrustWorthy(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  //Contact us
  getContactusall: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/contactusall');
      dispatch(adminGetContactusall(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteContactus: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/contactus/delete/${_id}`);
      dispatch(adminDeleteContactus(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // NETWORK RANGE
  getNetworkRange: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/networkRange');
      dispatch(adminGetNetworkRange(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addNetworkRange: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/networkRange/store', data);
      dispatch(adminAddNetworkRange(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateNetworkRange: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(
        `/api/v1/admin/networkRange/update/${_id}`,
        data,
      );
      dispatch(adminUpdateNetworkRange(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteNetworkRange: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/networkRange/delete/${_id}`);
      dispatch(adminDeleteNetworkRange(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // YYCServices
  getYYCServices: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/services');
      dispatch(adminGetYYCServices(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addYYCServices: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/services/store', data);
      dispatch(adminAddYYCServices(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateYYCServices: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/services/update/${_id}`, data);
      dispatch(adminUpdateYYCServices(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteYYCService: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/services/delete/${_id}`);
      dispatch(adminDeleteYYCServices(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  //Order
  getOrders: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/orders');
      dispatch(adminGetOrders(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateOrder: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/orders/${_id}`, data);
      dispatch(adminUpdateOrders(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteOrder: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/orders/${_id}`);
      dispatch(adminDeleteOrder(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // OnlineOrderServices
  getOnlineOrderServices: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/onlineOrderService');
      dispatch(adminGetOnlineOrderServices(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addOnlineOrderServices: async (data, dispatch) => {
    try {
      const res = await api.post(
        '/api/v1/admin/onlineOrderService/store',
        data,
      );
      dispatch(adminAddOnlineOrderServices(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateOnlineOrderServices: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(
        `/api/v1/admin/onlineOrderService/update/${_id}`,
        data,
      );
      dispatch(adminUpdateOnlineOrderServices(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteOnlineOrderService: async (_id, dispatch) => {
    try {
      const res = await api.delete(
        `/api/v1/admin/onlineOrderService/delete/${_id}`,
      );
      dispatch(adminDeleteOnlineOrderServices(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // About US
  getAboutUs: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/aboutus');
      dispatch(adminGetAboutUs(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addAboutUs: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/aboutus/store', data);
      dispatch(adminAddAboutUs(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateAboutUs: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/aboutus/update/${_id}`, data);
      dispatch(adminUpdateAboutUs(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteAbout: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/aboutus/delete/${_id}`);
      dispatch(adminDeleteAboutUs(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  //service images
  // About US
  getServiceImage: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/ServiceImage');
      dispatch(adminGetServiceImage(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addServiceImage: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/ServiceImage/store', data);
      dispatch(adminAddServiceImage(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateServiceImage: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(
        `/api/v1/admin/ServiceImage/update/${_id}`,
        data,
      );
      dispatch(adminUpdateServiceImage(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteServiceImage: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/ServiceImage/delete/${_id}`);
      dispatch(adminDeleteServiceImage(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Loction
  getLocation: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/location');
      dispatch(adminGetLocation(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addLocation: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/location/store', data);
      dispatch(adminAddLocation(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateLocation: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/location/update/${_id}`, data);
      dispatch(adminUpdateLocation(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteLocation: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/location/delete/${_id}`);
      dispatch(adminDeleteLocation(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // PAGES
  getPages: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/pages');
      dispatch(adminGetPages(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addPages: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/pages/store', data);
      dispatch(adminAddPages(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updatePages: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/pages/update/${_id}`, data);
      dispatch(adminUpdatePages(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deletePages: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/pages/delete/${_id}`);
      dispatch(adminDeletePages(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // PARTNERS
  getPartners: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/partners');
      dispatch(adminGetPartners(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addPartner: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/partners/store', data);
      dispatch(adminAddPartners(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updatePartner: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/partners/update/${_id}`, data);
      dispatch(adminUpdatePartners(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deletePartner: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/partners/delete/${_id}`);
      dispatch(adminDeletePartners(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default adminService;
