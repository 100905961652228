import React, { useEffect, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Container,
  makeStyles,
  Typography,
  Grid,
  Card,
  Button,
  Divider,
  Snackbar,
  Dialog,
  TextField,
} from '@material-ui/core';
import Page from 'src/components/Page';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import { useMatch } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import adminService from 'src/services/adminService';
import { Alert } from '@material-ui/lab';
import { DataUsage } from '@material-ui/icons';
import { DialogContent, DialogTitle } from 'src/components/CustomDialog';
import AssignDeliveryMan from './AssignDeliveryMan';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { toTitleCase } from 'src/utils/helper';

const orderStatusList = ['to be quoted', 'quoted', 'accepted'];
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  orderDetailsCard: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    minHeight: '60vh',
  },
  totalText: {
    fontWeight: 'bold',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const OrderDetails = () => {
  const navigate = useNavigate();

  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState(false);

  const dispatch = useDispatch();
  const orders = useSelector((state) => state.admin.orders);

  const { order_id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const returnPage = queryParams.get('return');

  const order = orders.find((Item) => Item._id.toString() === order_id);
  useEffect(() => {
    async function getOrders() {
      try {
        adminService.getOrders(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getOrders();
  }, []);
  // console.log(order_id);
  console.log(order);

  const classes = useStyles();

  const [data, setData] = useState({
    // ...order,
    status: '',
    quotedPrice: '',
  });
  useEffect(() => {
    if (order && order._id) {
      setData({
        status: order.status,
        quotedPrice: order.quotedPrice,
      });
    }
  }, [order]);

  const [updateBtnState, setUpdateBtnState] = useState(true);
  const handleChange = (event) => {
    setUpdateBtnState(false);
    const { name, value } = event.target;

    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const submitHandler = async () => {
    // console.log(order._id);
    try {
      let res = await adminService.updateOrder(order_id, data, dispatch);
      if (res && res.ok) {
        setUpdateSuccess(true);
        console.log(res);
      } else {
        setUpdateError(true);
      }
    } catch (error) {
      console.error(error);
      setUpdateError(true);
    }
    // console.log(data);
    // console.log(order._id);
  };

  const cancelBtnHandler = () => {
    setUpdateBtnState(true);
    setData({
      status: order.status,
      quotedPrice: order.quotedPrice,
    });
  };

  return (
    <>
      <Page className={classes.root} title="Order Details">
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="lg">
            <Box my={2}>
              <Typography variant="h2">Order Details</Typography>
            </Box>
            <Box my={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link color="gray" to="/">
                  Dashboard
                </Link>
                <Link color="grey" to={`/orders/filter/${returnPage}`}>
                  Orders
                </Link>
                <Typography color="textPrimary">Order Details</Typography>
              </Breadcrumbs>
            </Box>
            {order && order !== null ? (
              <Card className={classes.orderDetailsCard}>
                <Grid
                  container
                  alignItems="flex-start"
                  justify="center"
                  spacing={2}
                >
                  {/* Top Section starts */}
                  <Grid item xs={12} md={6}>
                    <Typography
                      style={{
                        marginTop: 2,
                        marginBottom: 2,
                      }}
                      variant="h4"
                    >
                      Order {/* Details Here of ID {id} Fetch from API */}
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography
                          style={{
                            marginTop: 5,
                            marginBottom: 2,
                            color: 'grey',
                          }}
                          variant="h6"
                        >
                          Shippment Id
                        </Typography>
                        <Typography
                          style={{
                            marginTop: 5,
                            marginBottom: 2,
                          }}
                        >
                          {order.shippmentNo}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          style={{
                            marginTop: 5,
                            marginBottom: 2,
                            color: 'grey',
                          }}
                          variant="h6"
                        >
                          Date
                        </Typography>
                        <Typography
                          style={{
                            marginTop: 5,
                            marginBottom: 2,
                          }}
                        >
                          {moment(order.createdAt).format('DD/MM/YYYY h:mm A')}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '200px' }}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Order Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            name="status"
                            value={data.status}
                            onChange={handleChange}
                            label="Order Status"
                          >
                            {orderStatusList.map((item) => (
                              <MenuItem value={item} key={item}>
                                {toTitleCase(item)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          id="outlined-basic"
                          label="Qutoed Price"
                          variant="outlined"
                          name="quotedPrice"
                          value={data.quotedPrice}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Box display="flex">
                          <Box p={1}>
                            <Button
                              flexGrow={1}
                              color="primary"
                              variant="contained"
                              onClick={submitHandler}
                              disabled={updateBtnState}
                            >
                              Update
                            </Button>
                          </Box>
                          <Box p={1}>
                            <Button
                              style={{ color: 'red', marginLeft: '5px' }}
                              variant="contained"
                              disabled={updateBtnState}
                              onClick={cancelBtnHandler}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} style={{ display: 'flex' }}>
                        <Grid item xs={6}>
                          <Typography
                            style={{
                              marginBottom: 10,
                            }}
                            variant="h4"
                          >
                            Service
                          </Typography>
                          <Typography
                            style={{
                              marginTop: 5,
                              marginBottom: 2,
                              color: '#6801CC',
                              fontWeight: 'bold',
                              cursor: 'pointer',
                            }}
                            variant="h6"
                          >
                            {order.service?.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            style={{
                              marginBottom: 10,
                            }}
                            variant="h4"
                          >
                            Offer
                          </Typography>
                          <Typography
                            style={{
                              marginTop: 5,
                              marginBottom: 2,
                              color: '#6801CC',
                              fontWeight: 'bold',
                              cursor: 'pointer',
                            }}
                            variant="h6"
                          >
                            {order.offer}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      style={{
                        // marginTop: 2,
                        marginBottom: 10,
                      }}
                      variant="h4"
                    >
                      Customer
                    </Typography>
                    <Typography
                      style={{
                        marginTop: 5,
                        marginBottom: 2,
                        color: '#6801CC',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      variant="h6"
                      // onClick={() =>
                      //   navigate(`/orders/customer_details/${order.user._id}`)
                      // }
                    >
                      <p>{order.user !== null ? order.user.name : ''}</p>
                    </Typography>
                    <Grid style={{ display: 'flex' }}>
                      <Grid md={6}>
                        <Typography
                          style={{
                            marginTop: 20,
                            marginBottom: 10,
                          }}
                          variant="h4"
                        >
                          Sender Information
                        </Typography>

                        <Typography
                          style={{
                            marginTop: 5,
                            marginBottom: 2,
                            color: '#6801CC',
                            fontWeight: 'bold',
                          }}
                          variant="h6"
                        >
                          <p>
                            {' '}
                            Name:{' '}
                            {order?.senderInformation
                              ? order?.senderInformation?.name
                              : ''}
                          </p>
                          <p>
                            {' '}
                            Company:{' '}
                            {order?.senderInformation
                              ? order?.senderInformation?.company
                              : ''}
                          </p>
                          <p>
                            {' '}
                            City:{' '}
                            {order?.senderInformation
                              ? order?.senderInformation?.city
                              : ''}
                          </p>
                          <p>
                            {' '}
                            Address:{' '}
                            {order?.senderInformation
                              ? order?.senderInformation?.address
                              : ''}
                          </p>
                          <p>
                            {' '}
                            Country:{' '}
                            {order?.senderInformation
                              ? order?.senderInformation?.country
                              : ''}
                          </p>
                          <p>
                            {' '}
                            Area code:{' '}
                            {order?.senderInformation
                              ? order?.senderInformation?.areaCode
                              : ''}
                          </p>
                          <p>
                            {' '}
                            Phone:{' '}
                            {order?.senderInformation
                              ? order?.senderInformation?.phone
                              : ''}
                          </p>
                        </Typography>
                      </Grid>
                      <Grid md={6}>
                        <Typography
                          style={{
                            marginTop: 20,
                            marginBottom: 10,
                          }}
                          variant="h4"
                        >
                          Receiver Information
                        </Typography>
                        <Typography
                          style={{
                            marginTop: 5,
                            marginBottom: 2,
                            color: '#6801CC',
                            fontWeight: 'bold',
                          }}
                          variant="h6"
                        >
                          <p>
                            {' '}
                            Name:{' '}
                            {order?.receiverInformation
                              ? order?.receiverInformation?.name
                              : ''}
                          </p>
                          <p>
                            {' '}
                            Company:{' '}
                            {order?.receiverInformation
                              ? order?.receiverInformation?.company
                              : ''}
                          </p>
                          <p>
                            {' '}
                            City:{' '}
                            {order?.receiverInformation
                              ? order?.receiverInformation?.city
                              : ''}
                          </p>
                          <p>
                            {' '}
                            Address:{' '}
                            {order?.receiverInformation
                              ? order?.receiverInformation?.address
                              : ''}
                          </p>
                          <p>
                            {' '}
                            Country:{' '}
                            {order?.receiverInformation
                              ? order?.receiverInformation?.country
                              : ''}
                          </p>
                          <p>
                            {' '}
                            Area code:{' '}
                            {order?.receiverInformation
                              ? order?.receiverInformation?.areaCode
                              : ''}
                          </p>
                          <p>
                            {' '}
                            Phone:{' '}
                            {order?.receiverInformation
                              ? order?.receiverInformation?.phone
                              : ''}
                          </p>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Top Section Ends */}
                  {/* Table Grid Starts */}
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        marginTop: 2,
                        marginBottom: 2,
                      }}
                      variant="h4"
                    >
                      Package
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Shipment Type</TableCell>
                            <TableCell align="right">Long</TableCell>
                            <TableCell align="right">Wide</TableCell>
                            <TableCell align="right">High</TableCell>
                            <TableCell align="right">Weight</TableCell>
                            <TableCell align="center">
                              Package Content
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order.package.map((row) => (
                            <TableRow key={row._id}>
                              <TableCell
                                style={{
                                  color: '#6801CC',
                                  fontWeight: 'bold',
                                  cursor: 'pointer',
                                }}
                              >
                                {row.shipmentType}
                              </TableCell>
                              <TableCell align="center">{row.long}</TableCell>
                              <TableCell align="center">{row.wide}</TableCell>
                              <TableCell align="center">{row.high}</TableCell>
                              <TableCell align="center">{row.weight}</TableCell>
                              <TableCell align="center">
                                <TableContainer>
                                  <Table
                                    className={classes.table}
                                    aria-label="simple table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Item name</TableCell>
                                        <TableCell align="center">
                                          Unit price
                                        </TableCell>
                                        <TableCell align="center">
                                          No of item
                                        </TableCell>
                                        <TableCell align="center">
                                          Country of origin
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {row.packageContent &&
                                      row.packageContent.length > 0
                                        ? row.packageContent.map(
                                            (pc, index) => (
                                              <TableRow key={index}>
                                                <TableCell align="center">
                                                  {pc.itemName}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {pc.unitPrice} ({pc.unit})
                                                </TableCell>
                                                <TableCell align="center">
                                                  {pc.noOfItem} ({pc.itemUnit})
                                                </TableCell>
                                                <TableCell align="center">
                                                  {pc.countryOfOrigin}
                                                </TableCell>
                                              </TableRow>
                                            ),
                                          )
                                        : 'Package content not defined'}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  {/* Table Grid Ends */}
                  {/* Total Table Grid Starts */}
                  {/* <Grid item xs={12}>
                    <Typography
                      style={{
                        marginTop: 2,
                        marginBottom: 2,
                      }}
                      variant="h4"
                    >
                      Quoted Price
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.totalText}>
                              Total
                            </TableCell>
                            <TableCell
                              className={classes.totalText}
                              align="center"
                            >
                              {order.quotedPrice}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid> */}
                  {/*Total Table Grid Ends */}
                </Grid>
              </Card>
            ) : (
              <Box mt={5} display="flex" justifyContent="center">
                <Typography
                  align="center"
                  color="textSecondary"
                  gutterBottom
                  variant="h3"
                >
                  Order Not Available
                </Typography>
              </Box>
            )}
          </Container>
        </Box>
        <Snackbar
          open={updateSuccess}
          autoHideDuration={3000}
          onClose={() => setUpdateSuccess(false)}
        >
          <Alert onClose={() => setUpdateSuccess(false)} severity="success">
            Update successfully.
          </Alert>
        </Snackbar>

        <Snackbar
          open={updateError}
          autoHideDuration={3000}
          onClose={() => setUpdateError(false)}
        >
          <Alert onClose={() => setUpdateError(false)} severity="error">
            Error Updating order info.
          </Alert>
        </Snackbar>
      </Page>
    </>
  );
};
export default OrderDetails;
