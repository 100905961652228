import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  InputLabel,
  Breadcrumbs,
  MenuItem,
  Typography,
  Avatar,
  Card,
  TextField,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import adminService from '../../services/adminService';
import { consoleError, getImageUrl } from '../../utils/helper';
import * as yup from 'yup';
// import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from '../../components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import CustomFileUpload from '../../components/CustomFileUpload';
import CustomFileUploadServer from '../../components/CustomFileUploadServer';
import { Editor } from '@tinymce/tinymce-react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    height: 400,
  },
  textArea: {
    width: '100%',
    height: 100,
  },
}));
function EditPages() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [id, setId] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);

  const match = useMatch('/pages/:id/edit');
  useEffect(() => {
    if (match && match.params && match.params.id) {
      setId(match.params.id);
      setUpdate(true);
    }
  }, [match]);

  const pages = useSelector((state) => state.admin.pages);
  const data = pages.find((Item) => Item._id.toString() === id);

  console.log({ pages, data, id });

  useEffect(() => {
    async function getAboutUs() {
      try {
        adminService.getAboutUs(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getAboutUs();
  }, []);

  const [title, setTitle] = useState('');
  const editorRef = useRef(data ? data.desc : null);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
    }
  }, [data]);

  const submitHandler = async () => {
    if (update) {
      try {
        let res = await adminService.updatePages(
          id,
          {
            title: title,
            desc: editorRef.current.getContent(),
          },
          dispatch,
        );
        if (res && res.ok) {
          setSuccessMsg(res.message || 'Pages updated successfully.');
        }
      } catch (error) {
        consoleError(error);
        setErrMsg(error.message || 'Error updating Pages. Please try again');
      }
    } else {
      try {
        let res = await adminService.addPages(
          {
            title: title,
            desc: editorRef.current.getContent(),
          },
          dispatch,
        );
        if (res && res.ok) {
          setSuccessMsg(res.message || 'Pages added successfully.');
        }
      } catch (error) {
        consoleError(error);
        setErrMsg(error.message || 'Error adding Pages. Please try again');
      }
    }
  };

  return (
    <Page className={classes.root} title={update ? 'Update' : 'Add'}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2}>
            <Typography variant="h2">{update ? 'Update' : 'Add'}</Typography>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/pages">
                Pages
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>

          <Card className={classes.editorCard}>
            <Grid
              container
              alignItems="flex-start"
              justify="center"
              spacing={2}
              style={{ marginTop: '20px' }}
            >
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="title"
                  variant="outlined"
                  name="title"
                  fullWidth={true}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  disabled={update ? true : false}
                />
              </Grid>
              <Grid item xs={12}>
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={update ? data.desc : ''}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                />
              </Grid>
              <Box mt={2}></Box>
            </Grid>
            <Box mt={1} mb={1}>
              {errMsg && (
                <Alert severity="error" onClose={() => setErrMsg('')}>
                  <AlertTitle>Error</AlertTitle>
                  {errMsg}
                </Alert>
              )}
              {successMsg && (
                <Alert severity="success" onClose={() => setSuccessMsg('')}>
                  <AlertTitle>Success</AlertTitle>
                  {successMsg}
                </Alert>
              )}
            </Box>
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                onClick={submitHandler}
              >
                {update ? 'Update data' : 'Add data'}
              </Button>
            </Box>
          </Card>
        </Container>
      </Box>
    </Page>
  );
}

export default EditPages;
