import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  User as UserIcon,
  Users as UsersIcon,
  ShoppingBag as Product,
  Image as imageIcon,
  BarChart2 as statIcon,
  MapPin as mapIcon,
  Book as contactIcon,
  Package as orderIcon,
  Layers as trustWorthyIcon,
  Radio as rangeIcon,
} from 'react-feather';
import NavItem from './NavItem';
import InputIcon from '@material-ui/icons/Input';

import userService from 'src/services/userService';
import { useDispatch, useSelector } from 'react-redux';
import { getImageUrl, isLoggedIn } from 'src/utils/helper';
import Navigation from 'src/components/Navigation';

const items = [
  {
    title: 'Main',
    pages: [
      {
        href: '/dashboard',
        icon: BarChartIcon,
        title: 'Dashboard',
      },
      {
        href: '/users',
        icon: UsersIcon,
        title: 'Users',
      },
      {
        href: '/site-setting',
        icon: SettingsIcon,
        title: 'Site Setting',
      },
      {
        href: '/banner',
        icon: imageIcon,
        title: 'Banner',
      },
      {
        href: '/stat',
        icon: statIcon,
        title: 'Stats',
      },
      {
        href: '/trustworthy',
        icon: trustWorthyIcon,
        title: 'Trust Worthy',
      },
      {
        href: '/networkrange',
        icon: rangeIcon,
        title: 'Network Range',
      },
      {
        href: '/contactus',
        icon: contactIcon,
        title: 'Contact Us',
      },
      {
        href: '/yycservices',
        icon: UsersIcon,
        title: 'Services',
      },
      {
        href: '/onlineOrderService',
        icon: UsersIcon,
        title: 'Online Order Services',
      },
      {
        href: '/orders',
        icon: orderIcon,
        title: 'Orders',
        children: [
          {
            href: '/orders/filter/to be quoted',
            icon: orderIcon,
            title: 'To be quoted',
          },
          {
            href: '/orders/filter/quoted',
            icon: orderIcon,
            title: 'Quoted',
          },
          {
            href: '/orders/filter/accepted',
            icon: orderIcon,
            title: 'Accepted',
          },
        ],
      },
      {
        href: '/about',
        icon: UsersIcon,
        title: 'About Us',
      },
      {
        href: '/service-images',
        icon: imageIcon,
        title: 'Service Images',
      },
      {
        href: '/partners',
        icon: UsersIcon,
        title: 'Partners',
      },
      {
        href: '/pages',
        icon: UsersIcon,
        title: 'Other Page',
      },
      {
        href: '/location',
        icon: mapIcon,
        title: 'Location',
      },
      {
        href: '/account',
        icon: UserIcon,
        title: 'Account',
      },

      {
        href: '/settings',
        icon: SettingsIcon,
        title: 'Settings',
      },
    ],
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);
  useEffect(() => {
    async function getUser() {
      try {
        await userService.profile(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    if (isLoggedIn() && !user?.name) {
      getUser();
    }
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={getImageUrl(user.avatar)}
          to="/account"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.role}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        {/* <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List> */}
        <nav>
          {items.map((list) => (
            <Navigation
              component="div"
              key={list.title}
              pages={list.pages}
              title={list.title}
            />
          ))}
        </nav>
      </Box>
      <Box flexGrow={1} />
      <Hidden lgUp>
        <Box p={2}>
          <List
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              userService.logout(dispatch);
            }}
          >
            <NavItem href="/logout" title="Logout" icon={InputIcon} />
          </List>
        </Box>
      </Hidden>
    </Box>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
