import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  FormControl,
  Select,
  InputLabel,
  Snackbar,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import adminService from 'src/services/adminService';
import api from 'src/services/api';
import { useParams } from 'react-router';
import { Alert } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AssignDeliveryMan = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { order_id } = useParams();

  const deliveryMans = useSelector((state) => state.admin.deliveryMans);

  useEffect(() => {
    async function getDeliveryMan() {
      try {
        adminService.getDeliveryMan(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getDeliveryMan();
  }, []);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [responseMsg, setResponseMsg] = useState('');
  const [errorResponseMsg, setErrorResponseMsg] = useState('');
  const [deliveryManState, setDeliveryManState] = useState();
  // const handleChange = (event) => {
  //   setDeliveryManState(value);
  // };
  const submitHandler = async () => {
    if (
      deliveryManState.status !== 'active' ||
      deliveryManState.busy === true ||
      deliveryManState.onDuty === false
    ) {
      setOpenErrorSnackbar(true);
      setErrorResponseMsg('Delivery man is not availabel at the moment');
    } else {
      try {
        const res = await adminService.assignOrder(
          order_id,
          {
            deliveryPerson: deliveryManState,
          },
          dispatch,
        );
        if (res && res.ok) {
          setOpenSuccessSnackbar(true);
          setResponseMsg('Delivery Person Assigned Successfully');
        }
      } catch (error) {
        setOpenErrorSnackbar(true);
        setErrorResponseMsg(error.message);
        return Promise.reject(error);
      }
    }
  };

  return (
    <Page className={classes.root} title="Assign Delivery Man">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container>
          <FormControl className={classes.formControl}>
            {/* <InputLabel htmlFor="age-native-simple">
              Choose Delivery Man
            </InputLabel> */}
            <Autocomplete
              id="combo-box-demo"
              options={deliveryMans}
              getOptionLabel={(option) => option.name}
              style={{ width: 400 }}
              onChange={(event, value) => setDeliveryManState(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Delevery man"
                  variant="outlined"
                />
              )}
            />
            {/* <Select
              native
              value={deliveryManState ? deliveryManState : ''}
              onChange={handleChange}
            >
              <option value={deliveryManState}>{deliveryManState}</option>
              {deliveryMans.map((data, index) => (
                <option key={index} value={data._id}>
                  {data.name}
                </option>
              ))}
            </Select> */}
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                onClick={submitHandler}
              >
                Submit
              </Button>
            </Box>
          </FormControl>
          <Snackbar
            open={openSuccessSnackbar}
            autoHideDuration={3000}
            onClose={() => setOpenSuccessSnackbar(false)}
          >
            <Alert
              onClose={() => setOpenSuccessSnackbar(false)}
              severity="success"
            >
              {responseMsg}
            </Alert>
          </Snackbar>

          <Snackbar
            open={openErrorSnackbar}
            autoHideDuration={3000}
            onClose={() => setOpenErrorSnackbar(false)}
          >
            <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error">
              {errorResponseMsg}
            </Alert>
          </Snackbar>
        </Container>
      </Box>
    </Page>
  );
};
export default AssignDeliveryMan;
