import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import api from 'src/services/api';

const FileUploadServer = (props) => (
  <FormControl>
    {props.label && (
      <InputLabel
        shrink
        style={{ transform: 'scale(1)' }}
        error={!!props.form.errors[props.field.name]}
      >
        {props.label}
      </InputLabel>
    )}
    <Input
      error={!!props.form.error}
      inputProps={{
        type: 'file',
        disabled: props.disabled || props.form.isSubmitting,
        name: props.field.name,
        onChange: async (event) => {
          const file = event.currentTarget.files[0];
          if (!file) return;
          const formData = new FormData();
          formData.append(props.field.name, file);
          try {
            props.setUploading(true);
            const res = await api.post(props.uploadUrl, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            const { url } = res.data;
            props.form.setFieldValue(props.fieldName, url ? url : '');
            // setUploading(false);
          } catch (error) {
            console.error(error);
            // setUploadError('Upload Error.');
            // setUploading(false);
          } finally {
            props.setUploading(false);
          }
        },
      }}
    />
    {props.form.errors[props.field.name] && (
      <FormHelperText error>
        {props.form.errors[props.field.name]}
      </FormHelperText>
    )}
  </FormControl>
);
FileUploadServer.propTypes = {
  label: PropTypes.string,
  form: PropTypes.any,
  uploadUrl: PropTypes.any,
  fieldName: PropTypes.any,
  field: PropTypes.any,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  setUploading: PropTypes.func,
};
export default FileUploadServer;
