import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import userService from 'src/services/userService';
import { Alert, AlertTitle } from '@material-ui/lab';
import { TextField } from 'formik-material-ui';

const useStyles = makeStyles(() => ({
  root: {},
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.profile);

  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setValues({
      ...values,
      ...user,
    });
  }, [user]);

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).min(2).required('Name is required'),
        phone: Yup.string()
          .matches(/^\+\d{9,15}$/, {
            message: 'Please enter valid number with country code.',
            excludeEmptyString: false,
          })
          .required('Phone is required'),
      })}
      onSubmit={async (values) => {
        try {
          setErrorMessage('');
          const { name, phone } = values;

          await userService.updateProfile({ name, phone }, dispatch);
        } catch (error) {
          // console.error(error);
          setErrorMessage(
            error.message || 'Update Profile Error. Please try again.',
          );
        }
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader="The information can be edited"
              title="Profile"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {errorMessage && (
                    <Alert severity="error" onClose={() => setErrorMessage('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errorMessage}
                    </Alert>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <Field
                    component={TextField}
                    fullWidth
                    helperText="Please specify the name"
                    label="Name"
                    name="name"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    component={TextField}
                    fullWidth
                    label="Email Address"
                    name="email"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    component={TextField}
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={submitForm}
                disabled={isSubmitting}
              >
                Save details
              </Button>
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
