import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { tableIcons } from 'src/components/common/TableIcons';
import { Trash2 as DeleteIcon } from 'react-feather';
import moment from 'moment';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  makeStyles,
  Dialog,
  DialogContent,
  Typography,
  Button,
  Snackbar,
} from '@material-ui/core';
import { DialogActions, DialogTitle } from 'src/components/CustomDialog';
import { Alert } from '@material-ui/lab';

import { useNavigate } from 'react-router';

import adminService from 'src/services/adminService';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const OrderTable = ({ className, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filterBy } = useParams();

  const orders = useSelector((state) => state.admin.orders);
  const filterData = orders.filter((d) => {
    return d.status === filterBy;
  });
  console.log(filterData);
  useEffect(() => {
    async function getOrders() {
      try {
        adminService.getOrders(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getOrders();
  }, [filterBy]);
  // console.log(orders);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteOrder, setDeleteOrder] = useState(null);

  const openDeleteModal = () => {
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
    setDeleteOrder(null);
  };

  const handleDeleteOrder = async () => {
    // console.log(`Deleted Order of ID : ${deleteOrder.id}}`);
    // closeDeleteModal();
    // setDeleteSuccess(true);
    try {
      let res = await adminService.deleteOrder(deleteOrder._id, dispatch);
      if (res && res.ok) {
        setDeleteSuccess(true);
        setDeleteModal(false);
        setDeleteOrder(null);
        navigate(`/orders`);
      } else {
        setDeleteError(true);
      }
    } catch (error) {
      console.error(error);
      setDeleteError(true);
    }
    // setDeleteError(true);
  };

  const rowClickHandler = (event, rowData) => {
    event.stopPropagation();
    console.log(rowData);
    navigate(`/orders/${rowData._id}?return=${filterBy}`);
  };

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <Box maxWidth={'95%'}>
          <MaterialTable
            icons={tableIcons}
            columns={[
              // {
              //   title: 'Id',
              //   field: '_id',
              // },
              {
                title: 'Date',
                field: 'createdAt',
                render: (rowData) =>
                  moment(rowData.createdAt).format('DD/MM/YYYY h:mm A'),
              },
              // { title: 'Address', field: 'shippingDetails.addressInfo' },
              {
                title: 'User',
                field: 'user',
                render: (rowData) => <span>{rowData.user.name}</span>,
              },
              { title: 'Quoted Price', field: 'quotedPrice' },
              { title: 'Status', field: 'status' },
            ]}
            data={filterData}
            title="Orders"
            actions={[
              {
                icon: DeleteIcon,
                tooltip: 'Delete Order',
                onClick: (event, rowData) => {
                  event.stopPropagation();
                  setDeleteOrder(rowData);
                  openDeleteModal();
                },
              },
            ]}
            options={{
              rowStyle: {
                fontSize: '1rem',
                fontWeight: 'lighter',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
              },
              actionsColumnIndex: -1,
            }}
            onRowClick={(event, rowData) => rowClickHandler(event, rowData)}
          />
        </Box>

        <Dialog open={deleteModal} onClose={closeDeleteModal}>
          <DialogTitle onClose={closeDeleteModal}>Delete Order</DialogTitle>
          <DialogContent>
            <Box
              height="50px"
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Typography variant="h6">
                Do you really want to delete Order?
              </Typography>
              <Typography variant="h6">
                &quot;
                <small>
                  <i>{deleteOrder?._id}</i>
                </small>
                &quot;
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteModal} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteOrder} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={deleteSuccess}
          autoHideDuration={3000}
          onClose={() => setDeleteSuccess(false)}
        >
          <Alert onClose={() => setDeleteSuccess(false)} severity="success">
            Order deleted successfully.
          </Alert>
        </Snackbar>

        <Snackbar
          open={deleteError}
          autoHideDuration={3000}
          onClose={() => setDeleteError(false)}
        >
          <Alert onClose={() => setDeleteError(false)} severity="error">
            Error deleting order.
          </Alert>
        </Snackbar>
      </Card>
    </>
  );
};

OrderTable.propTypes = {
  className: PropTypes.string,
};

export default OrderTable;
