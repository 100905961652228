import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import UserListView from 'src/views/user/UserListView';

import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';

import SettingsView from 'src/views/settings/SettingsView';
import { isLoggedIn } from './utils/helper';

import Error500 from './views/errors/Error500';

import SiteSettingView from './views/siteSetting';
import SiteSetting from './views/siteSetting/SiteSetting';
import EditSiteSetting from './views/siteSetting/EditSiteSetting';

import BannersView from './views/banner';
import Banner from './views/banner/Banner';
import EditBanner from './views/banner/EditBanner';

import StatsView from './views/stats';
import Stats from './views/stats/Stats';
import EditStat from './views/stats/EditStat';

import TrustWorthyView from './views/trustworthy';
import TrustWorthy from './views/trustworthy/TrustWorthy';
import EditTrustWorthy from './views/trustworthy/EditTrustWorthy';

import ContactView from './views/contact/ContactView';
import Contact from './views/contact/ContactView/Contact';

import NetworkRangeView from './views/networkrange';
import NetworkRange from './views/networkrange/NetworkRange';
import EditNetworkRange from './views/networkrange/EditNetworkRange';

import YYCServicesView from './views/yycservices';
import YYCServices from './views/yycservices/YYCServices';
import EditYYCServices from './views/yycservices/EditYYCServices';

import OnlineOrderServiceView from './views/onlineOrderService';
import OnlineOrderService from './views/onlineOrderService/OnlineOrderService';
import EditOnlineOrderService from './views/onlineOrderService/EditOnlineOrderService';

import OrderListView from './views/orders';
import OrderDetails from './views/orders/OrderDetails';
// import CustomerView from './views/orders/CustomerView';

import AboutUsView from './views/about';
import About from './views/about/About';
import EditAbout from './views/about/EditAbout';

import ServiceImagesView from './views/serviceImages';
import ServiceImages from './views/serviceImages/About';
import EditServiceImages from './views/serviceImages/EditAbout';

import LocaionView from './views/location';
import Location from './views/location/Location';
import EditLocation from './views/location/EditLocation';

import PagesView from './views/pages';
import Pages from './views/pages/Pages';
import EditPages from './views/pages/EditPages';

import PartnersView from './views/partners';
import Partners from './views/partners/Partners';
import EditPartners from './views/partners/EditPartners';

export const getRoutes = (pathname) => {
  const routes = [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: 'login',
          element: isLoggedIn() ? <Navigate to="/" /> : <LoginView />,
        },
        { path: '404', element: <NotFoundView /> },
        { path: '500', element: <Error500 /> },
        {
          path: '/',
          element: isLoggedIn() ? (
            <DashboardLayout />
          ) : (
            <Navigate to="/login" state={{ pathname }} />
          ),
          children: [
            { path: '/', element: <Navigate to="/dashboard" /> },
            { path: 'account', element: <AccountView /> },
            { path: 'users', element: <UserListView /> },
            { path: 'dashboard', element: <DashboardView /> },
            { path: 'settings', element: <SettingsView /> },
            { path: '*', element: <NotFoundView /> },
            {
              path: 'site-setting',
              children: [
                {
                  path: '/',
                  element: <SiteSettingView />,
                },
                {
                  path: '/view/:settingId',
                  element: <SiteSetting />,
                },
                {
                  path: '/edit/:settingId',
                  element: <EditSiteSetting />,
                },
                {
                  path: '/add',
                  element: <EditSiteSetting />,
                },
              ],
            },
            {
              path: 'banner',
              children: [
                {
                  path: '/',
                  element: <BannersView />,
                },
                {
                  path: ':bannerId',
                  element: <Banner />,
                },
                {
                  path: '/:bannerId/edit',
                  element: <EditBanner />,
                },
                {
                  path: '/add',
                  element: <EditBanner />,
                },
              ],
            },

            {
              path: 'stat',
              children: [
                {
                  path: '/',
                  element: <StatsView />,
                },
                {
                  path: ':statId',
                  element: <Stats />,
                },
                {
                  path: '/:statId/edit',
                  element: <EditStat />,
                },
                {
                  path: '/add',
                  element: <EditStat />,
                },
              ],
            },

            {
              path: 'trustworthy',
              children: [
                {
                  path: '/',
                  element: <TrustWorthyView />,
                },
                {
                  path: ':trustWorthyId',
                  element: <TrustWorthy />,
                },
                {
                  path: '/:trustWorthyId/edit',
                  element: <EditTrustWorthy />,
                },
                {
                  path: '/add',
                  element: <EditTrustWorthy />,
                },
              ],
            },

            {
              path: 'contactus',
              children: [
                {
                  path: '/',
                  element: <ContactView />,
                },
                {
                  path: ':contactId',
                  element: <Contact />,
                },
              ],
            },

            {
              path: 'networkrange',
              children: [
                {
                  path: '/',
                  element: <NetworkRangeView />,
                },
                {
                  path: ':networkRangeId',
                  element: <NetworkRange />,
                },
                {
                  path: '/:networkRangeId/edit',
                  element: <EditNetworkRange />,
                },
                {
                  path: '/add',
                  element: <EditNetworkRange />,
                },
              ],
            },

            {
              path: 'yycservices',
              children: [
                {
                  path: '/',
                  element: <YYCServicesView />,
                },
                {
                  path: ':yycserviceId',
                  element: <YYCServices />,
                },
                {
                  path: '/:yycserviceId/edit',
                  element: <EditYYCServices />,
                },
                {
                  path: '/add',
                  element: <EditYYCServices />,
                },
              ],
            },

            {
              path: 'onlineOrderService',
              children: [
                {
                  path: '/',
                  element: <OnlineOrderServiceView />,
                },
                {
                  path: ':onlineOrderServiceId',
                  element: <OnlineOrderService />,
                },
                {
                  path: '/:onlineOrderServiceId/edit',
                  element: <EditOnlineOrderService />,
                },
                {
                  path: '/add',
                  element: <EditOnlineOrderService />,
                },
              ],
            },

            {
              path: 'orders',
              children: [
                {
                  path: '/filter/:filterBy',
                  element: <OrderListView />,
                },
                {
                  path: '/:order_id',
                  element: <OrderDetails />,
                },
                // {
                //   path: '/customer_details/:customer_id',
                //   element: <CustomerView />,
                // },
              ],
            },

            {
              path: 'about',
              children: [
                {
                  path: '/',
                  element: <AboutUsView />,
                },
                {
                  path: ':aboutId',
                  element: <About />,
                },
                {
                  path: '/:aboutId/edit',
                  element: <EditAbout />,
                },
                {
                  path: '/add',
                  element: <EditAbout />,
                },
              ],
            },
            {
              path: 'service-images',
              children: [
                {
                  path: '/',
                  element: <ServiceImagesView />,
                },
                {
                  path: ':aboutId',
                  element: <ServiceImages />,
                },
                {
                  path: '/:aboutId/edit',
                  element: <EditServiceImages />,
                },
                {
                  path: '/add',
                  element: <EditServiceImages />,
                },
              ],
            },

            {
              path: 'partners',
              children: [
                {
                  path: '/',
                  element: <PartnersView />,
                },
                {
                  path: ':partnerId',
                  element: <Partners />,
                },
                {
                  path: '/:partnerId/edit',
                  element: <EditPartners />,
                },
                {
                  path: '/add',
                  element: <EditPartners />,
                },
              ],
            },

            {
              path: 'location',
              children: [
                {
                  path: '/',
                  element: <LocaionView />,
                },
                {
                  path: ':locationId',
                  element: <Location />,
                },
                {
                  path: '/:locationId/edit',
                  element: <EditLocation />,
                },
                {
                  path: '/add',
                  element: <EditLocation />,
                },
              ],
            },

            {
              path: 'pages',
              children: [
                {
                  path: '/',
                  element: <PagesView />,
                },
                {
                  path: ':pageId',
                  element: <Pages />,
                },
                {
                  path: '/:pageId/edit',
                  element: <EditPages />,
                },
                {
                  path: '/add',
                  element: <EditPages />,
                },
              ],
            },
          ],
        },
        { path: '*', element: <NotFoundView /> },
      ],
    },
    // {
    //   path: '/',
    //   element: isLoggedIn() ? <DashboardLayout /> : <Navigate to="/login" />,
    //   children: [
    //     { path: 'account', element: <AccountView /> },
    //     { path: 'customers', element: <UserListView /> },
    //     { path: 'dashboard', element: <DashboardView /> },
    //     { path: 'products', element: <ProductListView /> },
    //     { path: 'settings', element: <SettingsView /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
  ];
  return routes;
};

// export default routes;
