import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  InputLabel,
  Breadcrumbs,
  MenuItem,
  Typography,
  Avatar,
  Card,
  TextField,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import adminService from '../../services/adminService';
import { consoleError, getImageUrl } from '../../utils/helper';
import * as yup from 'yup';
// import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from '../../components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import CustomFileUpload from '../../components/CustomFileUpload';
import CustomFileUploadServer from '../../components/CustomFileUploadServer';
import { Editor } from '@tinymce/tinymce-react';
import config from '../../config';
import api from 'src/services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    height: 400,
  },
  textArea: {
    width: '100%',
    height: 100,
  },
}));
function EditServiceImages() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [id, setId] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);

  const match = useMatch('/service-images/:id/edit');
  useEffect(() => {
    if (match && match.params && match.params.id) {
      setId(match.params.id);
      setUpdate(true);
    }
  }, [match]);

  const aboutus = useSelector((state) => state.admin.serviceImages);
  const abotUs = aboutus.find((Item) => Item._id.toString() === id);

  console.log({ aboutus, abotUs, id });

  useEffect(() => {
    async function getAboutUs() {
      try {
        adminService.getServiceImage(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getAboutUs();
  }, []);

  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [tempImage, setTempImage] = useState();

  const [image2, setImage2] = useState('');
  const [tempImage2, setTempImage2] = useState();

  const [image3, setImage3] = useState('');
  const [tempImage3, setTempImage3] = useState();

  const [image4, setImage4] = useState('');
  const [tempImage4, setTempImage4] = useState();

  useEffect(() => {
    if (abotUs) {
      setImage(abotUs?.serviceImage);
      setImage2(abotUs?.quoteImage);
      setImage3(abotUs?.partnerImage);
      setImage3(abotUs?.instantQuoteImage);
    }
  }, [abotUs]);

  const editorRef1 = useRef(abotUs ? abotUs.desc1 : null);

  const editorRef2 = useRef(abotUs ? abotUs.desc2 : null);

  const uploadImageHandler = async (e, values, setFieldValue) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const image_New_Name = res.data;
      setTempImage(res.data);
      // values.image = image_New_Name;
      setImage(image_New_Name);
      console.log(values);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadImageHandler2 = async (e, values, setFieldValue) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const image_New_Name = res.data;
      setTempImage2(res.data);
      // values.image = image_New_Name;
      setImage2(image_New_Name);
      console.log(values);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadImageHandler3 = async (e, values, setFieldValue) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const image_New_Name = res.data;
      setTempImage3(res.data);
      // values.image = image_New_Name;
      setImage3(image_New_Name);
      console.log(values);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadImageHandler4 = async (e, values, setFieldValue) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const image_New_Name = res.data;
      setTempImage4(res.data);
      // values.image = image_New_Name;
      setImage4(image_New_Name);
      console.log(values);
    } catch (error) {
      console.error(error);
    }
  };

  const submitHandler = async () => {
    if (update) {
      try {
        let res = await adminService.updateServiceImage(
          id,
          {
            serviceImage: image,
            quoteImage: image2,
            partnerImage: image3,
            instantQuoteImage: image4,
          },
          dispatch,
        );
        if (res && res.ok) {
          setSuccessMsg(res.message || 'Service Images updated successfully.');
        }
      } catch (error) {
        consoleError(error);
        setErrMsg(
          error.message || 'Error updating Service Images. Please try again',
        );
      }
    } else {
      try {
        let res = await adminService.addServiceImage(
          {
            serviceImage: image,
            quoteImage: image2,
            partnerImage: image3,
            instantQuoteImage: image4,
          },
          dispatch,
        );
        if (res && res.ok) {
          setSuccessMsg(res.message || 'Service Images added successfully.');
        }
      } catch (error) {
        consoleError(error);
        setErrMsg(
          error.message || 'Error adding Service Images. Please try again',
        );
      }
    }
  };

  return (
    <Page
      className={classes.root}
      title={update ? 'Update Service Image' : 'Add Service Images'}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2}>
            <Typography variant="h2">
              {update ? 'Update Service Images' : 'Add Service Images'}
            </Typography>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/service-images">
                Service Images
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>

          <Card className={classes.editorCard}>
            <Grid
              container
              alignItems="flex-start"
              justify="center"
              spacing={2}
            >
              <Grid item xs={12} md={12}>
                <br />
                <Typography>Service Image</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <input
                  type="file"
                  name="serviceImage"
                  onChange={(e) => uploadImageHandler(e)}
                  accept="image/*"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {image ? (
                  <img
                    src={`${config.apiUrl}${config.imageUrlLocal}/images/${image}`}
                    alt="banner"
                    style={{ width: '200px', height: '200px' }}
                  />
                ) : tempImage ? (
                  <img
                    src={`${config.apiUrl}${config.imageUrlLocal}/images/${tempImage}`}
                    alt="banner"
                    style={{ width: '200px', height: '200px' }}
                  />
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography>Quote Image</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <input
                  type="file"
                  name="quoteImage"
                  onChange={(e) => uploadImageHandler2(e)}
                  accept="image/*"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {image2 ? (
                  <img
                    src={`${config.apiUrl}${config.imageUrlLocal}/images/${image2}`}
                    alt="banner"
                    style={{ width: '200px', height: '200px' }}
                  />
                ) : tempImage2 ? (
                  <img
                    src={`${config.apiUrl}${config.imageUrlLocal}/images/${tempImage2}`}
                    alt="banner"
                    style={{ width: '200px', height: '200px' }}
                  />
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography>Partner Image</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <input
                  type="file"
                  name="partnerImage"
                  onChange={(e) => uploadImageHandler3(e)}
                  accept="image/*"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {image3 ? (
                  <img
                    src={`${config.apiUrl}${config.imageUrlLocal}/images/${image3}`}
                    alt="banner"
                    style={{ width: '200px', height: '200px' }}
                  />
                ) : tempImage3 ? (
                  <img
                    src={`${config.apiUrl}${config.imageUrlLocal}/images/${tempImage3}`}
                    alt="banner"
                    style={{ width: '200px', height: '200px' }}
                  />
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography>Instant Quote Image (Calculator Image)</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <input
                  type="file"
                  name="instantQuoteImage"
                  onChange={(e) => uploadImageHandler4(e)}
                  accept="image/*"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {image4 ? (
                  <img
                    src={`${config.apiUrl}${config.imageUrlLocal}/images/${image4}`}
                    alt="banner"
                    style={{ width: '200px', height: '200px' }}
                  />
                ) : tempImage4 ? (
                  <img
                    src={`${config.apiUrl}${config.imageUrlLocal}/images/${tempImage4}`}
                    alt="banner"
                    style={{ width: '200px', height: '200px' }}
                  />
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            <Box mt={1} mb={1}>
              {errMsg && (
                <Alert severity="error" onClose={() => setErrMsg('')}>
                  <AlertTitle>Error</AlertTitle>
                  {errMsg}
                </Alert>
              )}
              {successMsg && (
                <Alert severity="success" onClose={() => setSuccessMsg('')}>
                  <AlertTitle>Success</AlertTitle>
                  {successMsg}
                </Alert>
              )}
            </Box>
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                onClick={submitHandler}
              >
                {update ? 'Update data' : 'Add data'}
              </Button>
            </Box>
          </Card>
        </Container>
      </Box>
    </Page>
  );
}

export default EditServiceImages;
