import { isString } from 'lodash';
import config from 'src/config';

export function isLoggedIn() {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    return true;
  }
  return false;
}

export function getImageUrl(path) {
  if (!path) return '';
  if (isString(path) && path.startsWith('http')) {
    return path;
  }
  return `${config.apiUrl}/api/${path}`;
}
export function getVideoUrl(path) {
  if (!path) return '';
  if (isString(path) && path.startsWith('http')) {
    return path;
  }
  return `${config.apiUrl}/api/${path}`;
}

export function getMediaUrl(path) {
  if (!path) return '';
  if (isString(path) && path.startsWith('http')) {
    return path;
  }
  return `${config.apiUrl}/api/${path}`;
}

export function consoleError(data) {
  if (process.env.NODE_ENV === 'development') console.error(data);
  return;
}
export function consoleLog(data) {
  if (process.env.NODE_ENV === 'development') console.log(data);
  return;
}

export const getBoolean = (value) => {
  switch (value) {
    case true:
    case 'true':
    case 'True':
    case 'TRUE':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true;
    default:
      return false;
  }
};
export const getBooleanString = (value) => {
  switch (value) {
    case true:
    case 'true':
    case 'True':
    case 'TRUE':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return 'True';
    default:
      return 'False';
  }
};

export function getDateTime(value) {
  if (!value || new Date(value).toString() === 'Invalid Date') return null;
  return new Date(value).toLocaleString();
}
export function getTimeOnly(value) {
  if (!value || new Date(value).toString() === 'Invalid Date') return null;
  return new Date(value).toLocaleTimeString();
}
export function getDateOnly(value) {
  if (!isDate(value)) return null;
  return new Date(value).toLocaleDateString();
}
export function isDate(value) {
  if (!value || new Date(value).toString() === 'Invalid Date') return false;
  return true;
}
export function formatAddress(address) {
  if (!address || typeof address !== 'object') return '';
  const {
    district = '',
    street = '',
    building = '',
    floor = '',
    flat = '',
  } = address;
  return `${district}, ${street}, ${building}, ${floor}, ${flat}`;
}
export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
}
