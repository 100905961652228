import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  InputLabel,
  Breadcrumbs,
  MenuItem,
  Typography,
  Avatar,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import adminService from '../../services/adminService';
import { consoleError } from '../../utils/helper';
import * as yup from 'yup';
import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from '../../components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import CustomFileUploadServer from '../../components/CustomFileUploadServer';
import { IndeterminateCheckBoxTwoTone } from '@material-ui/icons';
import FileUploadServer from '../../components/FileUploadServer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    height: 400,
  },
  officeDiv: {
    display: 'flex !important',
  },
  officeNo: {
    fontSize: '18px !important',
    color: '#a2a9b4 !important',
  },
}));
function EditSiteSetting() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [id, setId] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);

  const match = useMatch('/site-setting/edit/:id');
  useEffect(() => {
    if (match && match.params && match.params.id) {
      setId(match.params.id);
      setUpdate(true);
    }
  }, [match]);

  const siteSettings = useSelector((state) => state.admin.siteSettings);
  const settingData = siteSettings.find((Item) => Item._id.toString() === id);
  useEffect(() => {
    async function getSettings() {
      try {
        adminService.getSiteSetting(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getSettings();
  }, []);
  console.log(id);
  console.log(settingData);

  const addSiteSettingchema = yup.object().shape({
    title: yup
      .string()
      .required('title is required.')
      .min(2, 'title has min 2 chars.')
      .max(100, 'title has max 100 chars.'),
  });
  const updateSiteSettingchema = yup.object().shape({
    title: yup
      .string()
      .required('title is required.')
      .min(2, 'title has min 2 chars.')
      .max(30, 'title has max 100 chars.'),
  });

  return (
    <Page
      className={classes.root}
      title={update ? 'Update Setting' : 'Add Setting'}
    >
      <Box
        display="flex"
        flexdirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h2">
              {update ? 'Update setting' : 'Add setting'}
            </Typography>
            {/* <Box ml={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate('/site-setting/add');
                }}
              >
                Add New Setting
              </Button>
            </Box> */}
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/site-setting">
                Site Setting
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    ...settingData,
                    logoCurrentImage: settingData ? settingData.logo : '',
                    faviconCurrentImage: settingData ? settingData.favicon : '',
                  }
                : {
                    title: '',
                    phone: '',
                    email: '',
                    facebook: '',
                    instagram: '',
                    messenger: '',
                    whatsapp: '',
                    businesshours: '',
                    address: '',
                    favicon: '',
                    logo: '',
                  }
            }
            validationSchema={
              update ? updateSiteSettingchema : addSiteSettingchema
            }
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const {
                _id,
                title,
                phone,
                email,
                facebook,
                instagram,
                messenger,
                whatsapp,
                businesshours,
                address,
                favicon,
                logo,
              } = values;
              const data = {
                title,
                phone,
                email,
                facebook,
                instagram,
                messenger,
                whatsapp,
                businesshours,
                address,
                favicon,
                logo,
              };
              if (update) {
                try {
                  let res = await adminService.updateSiteSetting(
                    _id,
                    data,
                    dispatch,
                  );
                  if (res && res.ok) {
                    setSuccessMsg(
                      res.message || 'Setting updated successfully.',
                    );
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error updating setting. Please try again',
                  );
                }
              } else {
                try {
                  let res = await adminService.addSiteSetting(data, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'Setting added successfully.');
                    resetForm({});
                  }
                  //
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error adding setting. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Title"
                      margin="normal"
                      size="medium"
                      name="title"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Phone"
                      margin="normal"
                      size="medium"
                      name="phone"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Email"
                      margin="normal"
                      size="medium"
                      name="email"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Facebook"
                      margin="normal"
                      size="medium"
                      name="facebook"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Instagram"
                      margin="normal"
                      size="medium"
                      name="instagram"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Messenger"
                      margin="normal"
                      size="medium"
                      name="messenger"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="WhatsApp"
                      margin="normal"
                      size="medium"
                      name="whatsapp"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Business hours"
                      margin="normal"
                      size="medium"
                      name="businesshours"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Address"
                      margin="normal"
                      size="medium"
                      name="address"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Field
                        component={FileUploadServer}
                        uploadUrl="/api/v1/admin/stories/upload/favicon"
                        name="favicon"
                        label="FavIcon"
                        setUploading={setUploadingImage}
                        fieldName={`logo`}
                      />
                      <br />
                      {values.favicon || values.faviconCurrentImage ? (
                        <img
                          src={
                            values.favicon
                              ? values.favicon
                              : values.faviconCurrentImage
                          }
                          style={{
                            width: '100px',
                            height: '100px',
                            marginTop: '20px',
                          }}
                          alt="favicon"
                        />
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Field
                        component={CustomFileUploadServer}
                        uploadUrl="/api/v1/admin/stories/upload/logo"
                        name="logo"
                        label="Logo"
                        setUploading={setUploadingImage}
                      />
                      <br />

                      {values.logo || values.logoCurrentImage ? (
                        <img
                          src={
                            values.logo ? values.logo : values.logoCurrentImage
                          }
                          style={{
                            width: '100px',
                            height: '100px',
                            marginTop: '20px',
                          }}
                          alt="logo"
                        />
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {isSubmitting && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {update ? 'Update Setting' : 'Add Setting'}
                  </Button>
                </Box>
                <FormPreviewDev data={values} />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default EditSiteSetting;
