import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  InputLabel,
  Breadcrumbs,
  MenuItem,
  Typography,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import adminService from '../../services/adminService';
import { consoleError, getImageUrl } from '../../utils/helper';
import * as yup from 'yup';
import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from '../../components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import CustomFileUpload from '../../components/CustomFileUpload';
import CustomFileUploadServer from '../../components/CustomFileUploadServer';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import api from 'src/services/api';
// import FileUploadServer from '../../components/FileUploadServer';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    height: 400,
  },
}));
function EditTrustWorthy() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [id, setId] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);

  const match = useMatch('/trustworthy/:id/edit');
  useEffect(() => {
    if (match && match.params && match.params.id) {
      setId(match.params.id);
      setUpdate(true);
    }
  }, [match]);

  const trustworthy = useSelector((state) => state.admin.trustworthy);
  const data = trustworthy.find((Item) => Item._id.toString() === id);

  useEffect(() => {
    getTrustWorthy();
  }, []);

  const getTrustWorthy = async () => {
    try {
      adminService.getTrustWorthy(dispatch);
    } catch (error) {
      console.error(error);
    }
  };

  const addSiteSettingchema = yup.object().shape({
    title: yup
      .string()
      .required('title is required.')
      .min(2, 'title has min 2 chars.')
      .max(50, 'title has max 50 chars.'),
    image: yup.string().required('image is required.'),
  });
  const updateSiteSettingchema = yup.object().shape({
    title: yup
      .string()
      .required('title is required.')
      .min(2, 'title has min 2 chars.')
      .max(50, 'title has max 50 chars.'),
    image: yup.string().required('image is required.'),
  });

  const [tempImage, setTempImage] = useState();
  const uploadImageHandler = async (e, values, setFieldValue) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const image_New_Name = res.data;
      setTempImage(res.data);
      // values.image = image_New_Name;
      setFieldValue(e.target.name, image_New_Name);
      console.log(values);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page
      className={classes.root}
      title={update ? 'Update stat' : 'Add trustworthy'}
    >
      <Box
        display="flex"
        flexdirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h2">
              {update ? 'Update trustworthy' : 'Add trustworthy'}
            </Typography>
            <Box ml={2}>
              {/* <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate('/trustworthy/add');
                }}
              >
                Add New trustworthy
              </Button> */}
            </Box>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/trustworthy">
                trustworthy
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    ...data,
                  }
                : {
                    title: '',
                    image: '',
                  }
            }
            validationSchema={
              update ? updateSiteSettingchema : addSiteSettingchema
            }
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const { _id, title, image } = values;
              const data = {
                title,
                image,
              };
              if (update) {
                try {
                  let res = await adminService.updateTrustWorthy(
                    _id,
                    data,
                    dispatch,
                  );
                  if (res && res.ok) {
                    setSuccessMsg(
                      res.message || 'TrustWorthy updated successfully.',
                    );
                    setTimeout(() => {
                      window.location.reload();
                    }, 500);
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message ||
                      'Error updating TrustWorthy. Please try again',
                  );
                }
              } else {
                try {
                  let res = await adminService.addTrustWorthy(data, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(
                      res.message || 'TrustWorthy added successfully.',
                    );
                    resetForm({});
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message ||
                      'Error adding TrustWorthy. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Title"
                      margin="normal"
                      size="medium"
                      name="title"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <input
                      type="file"
                      name="image"
                      onChange={(e) =>
                        uploadImageHandler(e, values, setFieldValue)
                      }
                      accept="image/*"
                    />
                  </Grid>
                  {values.image ? (
                    <img
                      src={`${config.apiUrl}${config.imageUrlLocal}/images/${values.image}`}
                      alt="banner"
                      style={{ width: '200px', height: '200px' }}
                    />
                  ) : tempImage ? (
                    <img
                      src={`${config.apiUrl}${config.imageUrlLocal}/images/${tempImage}`}
                      alt="banner"
                      style={{ width: '200px', height: '200px' }}
                    />
                  ) : (
                    ''
                  )}
                </Grid>
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {(isSubmitting || uploadingImage) && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting || uploadingImage}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {update ? 'Update Data' : 'Add Data'}
                  </Button>
                </Box>
                <FormPreviewDev data={values} />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default EditTrustWorthy;
