import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Avatar,
  Box,
  Card,
  Typography,
  makeStyles,
  Dialog,
  DialogContent,
  Button,
  Snackbar,
} from '@material-ui/core';
import { consoleError, getImageUrl } from '../../utils/helper';
import MaterialTable from 'material-table';
import { tableIcons } from '../../components/common/TableIcons';
import { Edit as EditIcon, Trash2 as DeleteIcon } from 'react-feather';
import adminService from '../../services/adminService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DialogActions, DialogTitle } from '../../components/CustomDialog';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const PagesTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector((state) => state.admin.pages);
  useEffect(() => {
    async function getPages() {
      try {
        adminService.getPages(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getPages();
  }, []);

  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteAboutUs, setDeleteAboutUs] = useState(null);

  const openDeleteModal = () => {
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
    setDeleteAboutUs(null);
  };

  const handleDeleteAboutUs = async () => {
    try {
      await adminService.deletePages(deleteAboutUs._id, dispatch);
      closeDeleteModal();
      setDeleteSuccess(true);
    } catch (error) {
      consoleError(error);
      closeDeleteModal();
      setDeleteError(true);
    }
  };

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box maxWidth={'95%'}>
        <MaterialTable
          icons={tableIcons}
          columns={[{ title: 'Title', field: 'title', filtering: false }]}
          data={data}
          onRowClick={(event, rowData) => navigate(`./${rowData._id}`)}
          title="Pages"
          actions={[
            {
              icon: EditIcon,
              tooltip: 'Edit data',
              onClick: (event, rowData) => {
                event.stopPropagation();
                navigate(`/pages/${rowData._id}/edit`);
              },
            },
            {
              icon: DeleteIcon,
              tooltip: 'Delete Data',
              onClick: (event, rowData) => {
                event.stopPropagation();
                setDeleteAboutUs(rowData);
                openDeleteModal();
              },
            },
          ]}
          options={{
            rowStyle: {
              fontSize: '1rem',
              fontWeight: 'lighter',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
            },
            actionsColumnIndex: -1,
            filtering: true,
          }}
        />
      </Box>
      <Dialog open={deleteModal} onClose={closeDeleteModal}>
        <DialogTitle onClose={closeDeleteModal}>Delete</DialogTitle>
        <DialogContent>
          <Box
            height="50px"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h6">Do you really want to delete ?</Typography>
            <Typography variant="h6">
              &quot;
              <small>
                <i>{deleteAboutUs ? deleteAboutUs.name : ''}</i>
              </small>
              &quot;
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAboutUs} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={deleteSuccess}
        autoHideDuration={3000}
        onClose={() => setDeleteSuccess(false)}
      >
        <Alert onClose={() => setDeleteSuccess(false)} severity="success">
          Page Content deleted successfully.
        </Alert>
      </Snackbar>

      <Snackbar
        open={deleteError}
        autoHideDuration={3000}
        onClose={() => setDeleteError(false)}
      >
        <Alert onClose={() => setDeleteError(false)} severity="error">
          Error deleting Page Content.
        </Alert>
      </Snackbar>
    </Card>
  );
};

PagesTable.propTypes = {
  className: PropTypes.string,
};

export default PagesTable;
