import {
  Box,
  Card,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  InputLabel,
  Breadcrumbs,
  MenuItem,
  Typography,
  Avatar,
  CircularProgress,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import adminService from '../../services/adminService';
import { consoleError, getImageUrl } from '../../utils/helper';
import * as yup from 'yup';
import { TextField } from 'formik-material-ui';
import FormPreviewDev from '../../components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import CustomFileUpload from '../../components/CustomFileUpload';
import CustomFileUploadServer from '../../components/CustomFileUploadServer';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import api from 'src/services/api';
// import FileUploadServer from '../../components/FileUploadServer';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    height: 400,
  },
}));
function EditYYCServices() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [id, setId] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);

  const match = useMatch('/yycservices/:id/edit');
  useEffect(() => {
    if (match && match.params && match.params.id) {
      setId(match.params.id);
      setUpdate(true);
    }
  }, [match]);

  const yycservices = useSelector((state) => state.admin.yycservices);
  const data = yycservices.find((Item) => Item._id.toString() === id);

  useEffect(() => {
    getYYCServices();
  }, []);

  const getYYCServices = async () => {
    try {
      adminService.getYYCServices(dispatch);
    } catch (error) {
      console.error(error);
    }
  };

  const addSiteSettingchema = yup.object().shape({
    title: yup
      .string()
      .required('title is required.')
      .min(2, 'title has min 2 chars.')
      .max(50, 'title has max 50 chars.'),
  });
  const updateSiteSettingchema = yup.object().shape({
    title: yup
      .string()
      .required('title is required.')
      .min(2, 'title has min 2 chars.')
      .max(50, 'title has max 50 chars.'),
  });

  const uploadImageHandler = async (e, values, setFieldValue) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const image_New_Name = res.data;

      setFieldValue(e.target.name, image_New_Name);
      console.log(values);
    } catch (error) {
      console.error(error);
    }
  };

  const [toggleTabGlobal, setToggleTabGlobal] = useState(false);
  const [contentDataGlobal, setContentDataGlobal] = useState(false);
  const [sectionDataGlobal, setSectionDataGlobal] = useState(false);

  useEffect(() => {
    if (data && data.toggleTab && data.toggleTab.length > 0) {
      setToggleTabGlobal(true);
    }
    if (data && data.content) {
      setContentDataGlobal(true);
    }
    if (
      data &&
      data.sectionData &&
      data.sectionData.length > 0 &&
      data.sectionData.title !== ''
    ) {
      setSectionDataGlobal(true);
    }
  }, [data]);

  const [contentField] = useState({
    image: '',
    desc: '',
    orderNowBtn: false,
    getQuoteBtn: false,
    seeOtherService: false,
  });

  return (
    <Page className={classes.root} title={update ? 'Update data' : 'Add data'}>
      <Box
        display="flex"
        flexdirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h2">
              {update ? 'Update data' : 'Add data'}
            </Typography>
            <Box ml={2}>
              {/* <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate('/yycservices/add');
                }}
              >
                Add New data
              </Button> */}
            </Box>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/yycservices">
                YYC Services
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    ...data,
                  }
                : {
                    title: '',
                    desc: '',
                    image: '',
                    heroImage: '',
                    caption: '',
                    toggleTab: [
                      {
                        icon: '',
                        image: '',
                        title: '',
                        desc: '',
                        orderNowBtn: false,
                        getQuoteBtn: false,
                        seeOtherService: false,
                      },
                    ],
                    content: contentField,
                    sectionData: [
                      {
                        title: '',
                        list: [
                          {
                            title: '',
                            icon: '',
                          },
                        ],
                      },
                    ],
                  }
            }
            validationSchema={
              update ? updateSiteSettingchema : addSiteSettingchema
            }
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const {
                _id,
                title,
                desc,
                image,
                heroImage,
                caption,
                toggleTab,
                content,
                sectionData,
              } = values;
              const data = {
                title,
                desc,
                image,
                heroImage,
                caption,
                toggleTab,
                content,
                sectionData,
              };
              if (update) {
                try {
                  if (toggleTabGlobal) {
                    delete data.sectionData;
                    delete data.content;

                    let res = await adminService.updateYYCServices(
                      _id,
                      data,
                      dispatch,
                    );
                    if (res && res.ok) {
                      setSuccessMsg(
                        res.message || 'YYCServices updated successfully.',
                      );
                      setTimeout(() => {
                        window.location.reload();
                      }, 500);
                      return;
                    }
                  }
                  if (contentDataGlobal) {
                    delete data.sectionData;
                    delete data.toggleTab;
                    let res = await adminService.updateYYCServices(
                      _id,
                      data,
                      dispatch,
                    );
                    if (res && res.ok) {
                      setSuccessMsg(
                        res.message || 'YYCServices updated successfully.',
                      );
                      setTimeout(() => {
                        window.location.reload();
                      }, 500);
                      return;
                    }
                  }
                  if (sectionDataGlobal) {
                    delete data.content;
                    delete data.toggleTab;
                    let res = await adminService.updateYYCServices(
                      _id,
                      data,
                      dispatch,
                    );
                    if (res && res.ok) {
                      setSuccessMsg(
                        res.message || 'YYCServices updated successfully.',
                      );
                      setTimeout(() => {
                        window.location.reload();
                      }, 500);
                      return;
                    }
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message ||
                      'Error updating YYCServices. Please try again',
                  );
                }
              } else {
                try {
                  console.log({
                    toggleTabGlobal,
                    contentDataGlobal,
                    sectionDataGlobal,
                  });
                  if (toggleTabGlobal) {
                    delete data.sectionData;
                    delete data.content;

                    let res = await adminService.addYYCServices(data, dispatch);
                    if (res && res.ok) {
                      setSuccessMsg(
                        res.message || 'YYCServices added successfully.',
                      );
                      resetForm({});
                      return;
                    }
                  }
                  if (contentDataGlobal) {
                    delete data.sectionData;
                    delete data.toggleTab;

                    let res = await adminService.addYYCServices(data, dispatch);
                    if (res && res.ok) {
                      setSuccessMsg(
                        res.message || 'YYCServices added successfully.',
                      );
                      resetForm({});
                      return;
                    }
                  }
                  if (sectionDataGlobal) {
                    delete data.content;
                    delete data.toggleTab;

                    let res = await adminService.addYYCServices(data, dispatch);
                    if (res && res.ok) {
                      setSuccessMsg(
                        res.message || 'YYCServices added successfully.',
                      );
                      resetForm({});
                      return;
                    }
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message ||
                      'Error adding YYCServices. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Title"
                      margin="normal"
                      size="medium"
                      name="title"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Description"
                      margin="normal"
                      size="medium"
                      name="desc"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Caption"
                      margin="normal"
                      size="medium"
                      name="caption"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>

                  <Typography variant="h6" xs={12} md={12}>
                    Image
                  </Typography>
                  <Grid item xs={12} md={12}>
                    <input
                      type="file"
                      name={`image`}
                      onChange={(e) =>
                        uploadImageHandler(e, values, setFieldValue)
                      }
                      accept="image/*"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {values.image ? (
                      <img
                        src={`${config.apiUrl}${config.imageUrlLocal}/images/${values.image}`}
                        alt="Service"
                        style={{
                          width: '100px',
                          height: '100px',
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Grid>

                  <Typography variant="h6" xs={12} md={12}>
                    Hero Image
                  </Typography>
                  <Grid item xs={12} md={12}>
                    <input
                      type="file"
                      name={`heroImage`}
                      onChange={(e) =>
                        uploadImageHandler(e, values, setFieldValue)
                      }
                      accept="image/*"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {values.heroImage ? (
                      <img
                        src={`${config.apiUrl}${config.imageUrlLocal}/images/${values.heroImage}`}
                        alt="Service"
                        style={{
                          width: '100px',
                          height: '100px',
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Grid>

                  {/* ===== TOGGLE TAB CONTENT DATA STARTS */}

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={toggleTabGlobal}
                          onChange={() => setToggleTabGlobal(!toggleTabGlobal)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="Toggle Tab Format ?"
                    />
                  </Grid>
                  {toggleTabGlobal ? (
                    <Card
                      style={{
                        width: '90vw',
                        minHeight: '40vh',
                        padding: '20px',
                      }}
                    >
                      <Box maxWidth={'95%'}>
                        <Grid item xs={12}>
                          <FieldArray name="toggleTab">
                            {({ remove, push }) => (
                              <Box>
                                {values.toggleTab &&
                                values.toggleTab.length > 0 ? (
                                  values.toggleTab.map((toggleTab, index) => (
                                    <Grid
                                      container
                                      key={index}
                                      spacing={2}
                                      style={{
                                        padding: '40px',
                                        border: '1px black solid',
                                      }}
                                    >
                                      <Typography variant="h6" xs={12} md={12}>
                                        Icon
                                      </Typography>
                                      <Grid item xs={12} md={12}>
                                        <input
                                          type="file"
                                          name={`toggleTab[${index}].icon`}
                                          onChange={(e) =>
                                            uploadImageHandler(
                                              e,
                                              values,
                                              setFieldValue,
                                            )
                                          }
                                          accept="image/*"
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={12}>
                                        {values.toggleTab[index].icon ? (
                                          <img
                                            src={`${config.apiUrl}${config.imageUrlLocal}/images/${values.toggleTab[index].icon}`}
                                            alt="Service"
                                            style={{
                                              width: '100px',
                                              height: '100px',
                                            }}
                                          />
                                        ) : (
                                          ''
                                        )}
                                      </Grid>
                                      <Typography variant="h6" xs={12} md={12}>
                                        Image
                                      </Typography>
                                      <Grid item xs={12} md={12}>
                                        <input
                                          type="file"
                                          name={`toggleTab[${index}].image`}
                                          onChange={(e) =>
                                            uploadImageHandler(
                                              e,
                                              values,
                                              setFieldValue,
                                            )
                                          }
                                          accept="image/*"
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={12}>
                                        {values.toggleTab[index].icon ? (
                                          <img
                                            src={`${config.apiUrl}${config.imageUrlLocal}/images/${values.toggleTab[index].image}`}
                                            alt="Service"
                                            style={{
                                              width: '100px',
                                              height: '100px',
                                            }}
                                          />
                                        ) : (
                                          ''
                                        )}
                                      </Grid>
                                      <Grid item xs={12} md={12}>
                                        <Field
                                          component={TextField}
                                          label="Title"
                                          size="medium"
                                          name={`toggleTab.${index}.title`}
                                          fullWidth
                                          variant="outlined"
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={12}>
                                        <Field
                                          component={TextField}
                                          label="Description"
                                          size="medium"
                                          name={`toggleTab.${index}.desc`}
                                          fullWidth
                                          variant="outlined"
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              name={`toggleTab[${index}].orderNowBtn`}
                                              checked={
                                                values.toggleTab[index]
                                                  .orderNowBtn
                                              }
                                              onChange={(e) =>
                                                setFieldValue(
                                                  e.target.name,
                                                  !values.toggleTab[index]
                                                    .orderNowBtn,
                                                )
                                              }
                                              inputProps={{
                                                'aria-label': 'controlled',
                                              }}
                                            />
                                          }
                                          label="Display Order Now Button"
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              name={`toggleTab[${index}].getQuoteBtn`}
                                              checked={
                                                values.toggleTab[index]
                                                  .getQuoteBtn
                                              }
                                              onChange={(e) =>
                                                setFieldValue(
                                                  e.target.name,
                                                  !values.toggleTab[index]
                                                    .getQuoteBtn,
                                                )
                                              }
                                              inputProps={{
                                                'aria-label': 'controlled',
                                              }}
                                            />
                                          }
                                          label="Display Get Quote Button"
                                        />
                                      </Grid>

                                      <Grid item xs={12}>
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              name={`toggleTab[${index}].seeOtherService`}
                                              checked={
                                                values.toggleTab[index]
                                                  .seeOtherService
                                              }
                                              onChange={(e) =>
                                                setFieldValue(
                                                  e.target.name,
                                                  !values.toggleTab[index]
                                                    .seeOtherService,
                                                )
                                              }
                                              inputProps={{
                                                'aria-label': 'controlled',
                                              }}
                                            />
                                          }
                                          label="See Other Service Button"
                                        />
                                      </Grid>

                                      <Grid
                                        xs={12}
                                        md={12}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          onClick={() => remove(index)}
                                          style={{
                                            marginLeft: '10px',
                                            marginRight: '10px',
                                          }}
                                        >
                                          <RemoveIcon />
                                          Remove
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          onClick={() =>
                                            push({
                                              icon: '',
                                              image: '',
                                              title: '',
                                              desc: '',
                                              orderNowBtn: false,
                                              getQuoteBtn: false,
                                              seeOtherService: false,
                                            })
                                          }
                                        >
                                          <AddIcon />
                                          Add New
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  ))
                                ) : (
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                      <input
                                        type="file"
                                        name={`toggleTab[${
                                          values.toggleTab &&
                                          values.toggleTab.length
                                        }].icon`}
                                        onChange={(e) =>
                                          uploadImageHandler(
                                            e,
                                            values,
                                            setFieldValue,
                                          )
                                        }
                                        accept="image/*"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <input
                                        type="file"
                                        name={`toggleTab[${
                                          values.toggleTab &&
                                          values.toggleTab.length
                                        }].image`}
                                        onChange={(e) =>
                                          uploadImageHandler(
                                            e,
                                            values,
                                            setFieldValue,
                                          )
                                        }
                                        accept="image/*"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <Field
                                        component={TextField}
                                        label="Title"
                                        size="medium"
                                        name={`toggleTab.${
                                          values.toggleTab &&
                                          values.toggleTab.length
                                        }.title`}
                                        fullWidth
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <Field
                                        component={TextField}
                                        label="Description"
                                        size="medium"
                                        name={`toggleTab.${
                                          values.toggleTab &&
                                          values.toggleTab.length
                                        }.desc`}
                                        fullWidth
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid
                                      xs={2}
                                      md={4}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() =>
                                          remove(values.meansOfTransport.length)
                                        }
                                        style={{
                                          marginLeft: '10px',
                                          marginRight: '10px',
                                        }}
                                      >
                                        <RemoveIcon />
                                        Remove
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() =>
                                          push({
                                            icon: '',
                                            image: '',
                                            title: '',
                                            desc: '',
                                            orderNowBtn: false,
                                            getQuoteBtn: false,
                                            seeOtherService: false,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                        Add new
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )}
                              </Box>
                            )}
                          </FieldArray>
                        </Grid>
                      </Box>
                    </Card>
                  ) : (
                    ''
                  )}
                  {/* ===== TOGGLE TAB CONTENT DATA ENDS */}

                  {/* ===== CONTENT DATA STARTS */}

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={contentDataGlobal}
                          onChange={() =>
                            setContentDataGlobal(!contentDataGlobal)
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="Content Data  Format ?"
                    />
                  </Grid>
                  {contentDataGlobal ? (
                    <Card
                      style={{
                        width: '90vw',
                        minHeight: '40vh',
                        padding: '20px',
                      }}
                    >
                      <Box maxWidth={'95%'}>
                        <Typography variant="h6" xs={12} md={12}>
                          Image
                        </Typography>
                        <Grid item xs={12} md={12}>
                          <input
                            type="file"
                            name={`content.image`}
                            onChange={(e) =>
                              uploadImageHandler(e, values, setFieldValue)
                            }
                            accept="image/*"
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          {values.content.image ? (
                            <img
                              src={`${config.apiUrl}${config.imageUrlLocal}/images/${values.content.image}`}
                              alt="Service"
                              style={{
                                width: '100px',
                                height: '100px',
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Field
                            component={TextField}
                            label="Description"
                            size="medium"
                            name={`content.desc`}
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                name={`content.orderNowBtn`}
                                checked={values.content.orderNowBtn}
                                onChange={(e) =>
                                  setFieldValue(
                                    e.target.name,
                                    !values.content.orderNowBtn,
                                  )
                                }
                                inputProps={{
                                  'aria-label': 'controlled',
                                }}
                              />
                            }
                            label="Display Order Now Button"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                name={`content.getQuoteBtn`}
                                checked={values.content.getQuoteBtn}
                                onChange={(e) =>
                                  setFieldValue(
                                    e.target.name,
                                    !values.content.getQuoteBtn,
                                  )
                                }
                                inputProps={{
                                  'aria-label': 'controlled',
                                }}
                              />
                            }
                            label="Display Get Quote Button"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                name={`content.seeOtherService`}
                                checked={values.content.seeOtherService}
                                onChange={(e) =>
                                  setFieldValue(
                                    e.target.name,
                                    !values.content.seeOtherService,
                                  )
                                }
                                inputProps={{
                                  'aria-label': 'controlled',
                                }}
                              />
                            }
                            label="See Other Service Button"
                          />
                        </Grid>
                      </Box>
                    </Card>
                  ) : (
                    ''
                  )}
                  {/* ===== CONTENT DATA ENDS */}

                  {/* ===== SECTION DATA FORMAT STARTS */}
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={sectionDataGlobal}
                          onChange={() =>
                            setSectionDataGlobal(!sectionDataGlobal)
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="Section Data Format ?"
                    />
                  </Grid>
                  {sectionDataGlobal ? (
                    <Card
                      style={{
                        width: '90vw',
                        minHeight: '40vh',
                        padding: '20px',
                      }}
                    >
                      <Box maxWidth={'95%'}>
                        <Grid item xs={12}>
                          <FieldArray name="sectionData">
                            {({ remove, push }) => (
                              <Box>
                                {values.sectionData &&
                                values.sectionData.length > 0 ? (
                                  values.sectionData.map((d, index) => (
                                    <Grid
                                      container
                                      key={index}
                                      spacing={2}
                                      style={{
                                        padding: '40px',
                                        border: '1px black solid',
                                      }}
                                    >
                                      <Grid item xs={12} md={12}>
                                        <Field
                                          component={TextField}
                                          label="Title"
                                          size="medium"
                                          name={`sectionData.${index}.title`}
                                          fullWidth
                                          variant="outlined"
                                        />
                                      </Grid>
                                      <FieldArray
                                        name={`sectionData.${index}.list`}
                                      >
                                        {({ remove, push }) => (
                                          <Box
                                            minWidth={'90%'}
                                            style={{ paddingLeft: '40px' }}
                                          >
                                            <Typography>LIST ITEMS</Typography>
                                            {values.sectionData[index].list &&
                                            values.sectionData[index].list
                                              .length > 0
                                              ? values.sectionData[
                                                  index
                                                ].list.map((da, ind) => (
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    key={ind}
                                                    style={{
                                                      border: '1px black solid',
                                                      padding: '20px',
                                                      marginBottom: '20px',
                                                    }}
                                                  >
                                                    <Field
                                                      style={{
                                                        marginBottom: '20px',
                                                        marginTop: '20px',
                                                      }}
                                                      component={TextField}
                                                      label="Title"
                                                      size="medium"
                                                      name={`sectionData.${index}.list.${ind}.title`}
                                                      fullWidth
                                                      variant="outlined"
                                                    />
                                                    <Grid item xs={12} md={12}>
                                                      <input
                                                        type="file"
                                                        name={`sectionData.${index}.list.${ind}.icon`}
                                                        onChange={(e) =>
                                                          uploadImageHandler(
                                                            e,
                                                            values,
                                                            setFieldValue,
                                                          )
                                                        }
                                                        accept="image/*"
                                                      />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                      {values.sectionData[index]
                                                        .list[ind].icon ? (
                                                        <img
                                                          src={`${config.apiUrl}${config.imageUrlLocal}/images/${values.sectionData[index].list[ind].icon}`}
                                                          alt="Service"
                                                          style={{
                                                            width: '100px',
                                                            height: '100px',
                                                          }}
                                                        />
                                                      ) : (
                                                        ''
                                                      )}
                                                    </Grid>

                                                    <Grid
                                                      style={{
                                                        paddingTop: '40px',
                                                        paddingBottom: '40px',
                                                      }}
                                                    >
                                                      <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() =>
                                                          remove(ind)
                                                        }
                                                        style={{
                                                          marginLeft: '10px',
                                                          marginRight: '10px',
                                                        }}
                                                      >
                                                        <RemoveIcon />
                                                        Remove
                                                      </Button>
                                                      <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() =>
                                                          push({
                                                            title: '',
                                                            icon: '',
                                                          })
                                                        }
                                                      >
                                                        <AddIcon />
                                                        Add New
                                                      </Button>
                                                    </Grid>
                                                  </Grid>
                                                ))
                                              : ''}
                                          </Box>
                                        )}
                                      </FieldArray>

                                      <Grid
                                        xs={12}
                                        md={12}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          onClick={() => remove(index)}
                                          style={{
                                            marginLeft: '10px',
                                            marginRight: '10px',
                                          }}
                                        >
                                          <RemoveIcon />
                                          Remove
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          onClick={() =>
                                            push({
                                              title: '',
                                              desc: '',
                                              list: [
                                                {
                                                  title: '',
                                                  icon: '',
                                                },
                                              ],
                                            })
                                          }
                                        >
                                          <AddIcon />
                                          Add New
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  ))
                                ) : (
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                      <input
                                        type="file"
                                        name={`toggleTab[${
                                          values.toggleTab &&
                                          values.toggleTab.length
                                        }].icon`}
                                        onChange={(e) =>
                                          uploadImageHandler(
                                            e,
                                            values,
                                            setFieldValue,
                                          )
                                        }
                                        accept="image/*"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <input
                                        type="file"
                                        name={`toggleTab[${
                                          values.toggleTab &&
                                          values.toggleTab.length
                                        }].image`}
                                        onChange={(e) =>
                                          uploadImageHandler(
                                            e,
                                            values,
                                            setFieldValue,
                                          )
                                        }
                                        accept="image/*"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <Field
                                        component={TextField}
                                        label="Title"
                                        size="medium"
                                        name={`toggleTab.${
                                          values.toggleTab &&
                                          values.toggleTab.length
                                        }.title`}
                                        fullWidth
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <Field
                                        component={TextField}
                                        label="Description"
                                        size="medium"
                                        name={`toggleTab.${
                                          values.toggleTab &&
                                          values.toggleTab.length
                                        }.desc`}
                                        fullWidth
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid
                                      xs={2}
                                      md={4}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() =>
                                          remove(values.meansOfTransport.length)
                                        }
                                        style={{
                                          marginLeft: '10px',
                                          marginRight: '10px',
                                        }}
                                      >
                                        <RemoveIcon />
                                        Remove
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() =>
                                          push({
                                            title: '',
                                            list: [
                                              {
                                                title: '',
                                                icon: '',
                                              },
                                            ],
                                          })
                                        }
                                      >
                                        <AddIcon />
                                        Add new
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )}
                              </Box>
                            )}
                          </FieldArray>
                        </Grid>
                      </Box>
                    </Card>
                  ) : (
                    ''
                  )}
                  {/* ===== SECTION DATA FORMAT ENDS */}
                </Grid>
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {(isSubmitting || uploadingImage) && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting || uploadingImage}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {update ? 'Update Data' : 'Add Data'}
                  </Button>
                </Box>
                <FormPreviewDev data={values} />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default EditYYCServices;
