import React, { useEffect } from 'react';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  //   CardHeader,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Breadcrumbs,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import adminService from '../../services/adminService';
import { useParams } from 'react-router';
import { getImageUrl } from '../../utils/helper';
import { useNavigate } from 'react-router';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  mb3: {
    marginBottom: '15px',
  },
  serviceImage: {
    maxHeight: '400px',
  },
  name: {
    textTransform: 'capitalize',
  },
}));

function Stats() {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { statId } = useParams();
  console.log(statId);

  const stats = useSelector((state) => state.admin.stats);
  // console.log(services);
  const data = stats.find((Item) => Item._id.toString() === statId);
  // console.log(data);
  useEffect(() => {
    async function getStats() {
      try {
        adminService.getStats(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getStats();
  }, []);

  return (
    <Page className={classes.root} title="stats">
      <Container maxWidth={false}>
        <Box mt={3}>
          <Typography color="textPrimary" gutterBottom variant="h2">
            stats
          </Typography>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/stat">
                stats
              </Link>
              <Typography color="textPrimary">
                {data ? data._id : ''}
              </Typography>
            </Breadcrumbs>
          </Box>
          {data ? (
            <Box>
              <Card>
                <Box display="flex" ml={2} mt={2} alignItems="center">
                  <Typography color="textPrimary" variant="h4">
                    Details{' '}
                  </Typography>
                </Box>
                <CardContent>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Id
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data._id}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Title
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data.title}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Number
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data.number}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Suffix
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data.suffix}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Image
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <img
                              src={`${config.apiUrl}${config.imageUrlLocal}/images/${data.image}`}
                              alt="banner"
                              className={classes.serviceImage}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Box>
          ) : (
            <Box mt={5} display="flex" justifyContent="center">
              <Typography
                align="center"
                color="textSecondary"
                gutterBottom
                variant="h3"
              >
                Stats Not Available
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
}
export default Stats;
