import {
  ADMIN_ADD_USER,
  ADMIN_DELETE_USER,
  ADMIN_GET_USERS,
  ADMIN_UPDATE_USER,
  ADMIN_GET_SITE_SETTING,
  ADMIN_UPDATE_SITE_SETTING,
  ADMIN_GET_BANNER,
  ADMIN_ADD_BANNER,
  ADMIN_UPDATE_BANNER,
  ADMIN_DELETE_BANNER,
  ADMIN_GET_STAT,
  ADMIN_ADD_STAT,
  ADMIN_UPDATE_STAT,
  ADMIN_DELETE_STAT,
  ADMIN_GET_TRUSTWORTHY,
  ADMIN_ADD_TRUSTWORTHY,
  ADMIN_UPDATE_TRUSTWORTHY,
  ADMIN_DELETE_TRUSTWORTHY,
  ADMIN_GET_CONTACTUSALL,
  ADMIN_DELETE_CONTACTUS,
  ADMIN_GET_NETWORKRANGE,
  ADMIN_ADD_NETWORKRANGE,
  ADMIN_UPDATE_NETWORKRANGE,
  ADMIN_DELETE_NETWORKRANGE,
  ADMIN_GET_YYCSERVICES,
  ADMIN_ADD_YYCSERVICES,
  ADMIN_UPDATE_YYCSERVICES,
  ADMIN_DELETE_YYCSERVICES,
  ADMIN_GET_ORDERS,
  ADMIN_UPDATE_ORDER,
  ADMIN_DELETE_ORDER,
  ADMIN_GET_ONLINE_ORDER_SERVICES,
  ADMIN_ADD_ONLINE_ORDER_SERVICES,
  ADMIN_UPDATE_ONLINE_ORDER_SERVICES,
  ADMIN_DELETE_ONLINE_ORDER_SERVICES,
  ADMIN_GET_ABOUT_US,
  ADMIN_ADD_ABOUT_US,
  ADMIN_UPDATE_ABOUT_US,
  ADMIN_DELETE_ABOUT_US,
  ADMIN_GET_SERVICE_IMAGE,
  ADMIN_ADD_SERVICE_IMAGE,
  ADMIN_UPDATE_SERVICE_IMAGE,
  ADMIN_DELETE_SERVICE_IMAGE,
  ADMIN_GET_LOCATION,
  ADMIN_ADD_LOCATION,
  ADMIN_UPDATE_LOCATION,
  ADMIN_DELETE_LOCATION,
  ADMIN_GET_PAGES,
  ADMIN_ADD_PAGES,
  ADMIN_UPDATE_PAGES,
  ADMIN_DELETE_PAGES,
  ADMIN_GET_PARTNERS,
  ADMIN_ADD_PARTNERS,
  ADMIN_UPDATE_PARTNERS,
  ADMIN_DELETE_PARTNERS,
} from '../actions/adminActions';

const initialState = {
  users: [],
  siteSettings: [],
  banners: [],
  stats: [],
  trustworthy: [],
  contactus: [],
  networkrange: [],
  yycservices: [],
  orders: [],
  onlineOrderService: [],
  aboutus: [],
  serviceImages: [],
  location: [],
  pages: [],
  partners: [],
};

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    // USERS
    case ADMIN_GET_USERS:
      return {
        ...state,
        users: [...action.payload],
      };
    case ADMIN_ADD_USER: {
      const users = [...state.users];
      users.push(action.payload);
      return {
        ...state,
        users: users,
      };
    }
    case ADMIN_UPDATE_USER: {
      const users = [...state.users];
      let index = users.findIndex((item) => item._id === action.payload._id);
      users[index] = action.payload;
      return {
        ...state,
        users: users,
      };
    }
    case ADMIN_DELETE_USER: {
      const users = [...state.users];
      let index = users.findIndex((item) => item._id === action.payload);
      users.splice(index, 1);
      return {
        ...state,
        users: users,
      };
    }

    // SITE SETTING
    case ADMIN_GET_SITE_SETTING:
      return {
        ...state,
        siteSettings: [action.payload],
      };
    case ADMIN_UPDATE_SITE_SETTING: {
      const siteSettings = [...state.siteSettings];
      let index = siteSettings.findIndex(
        (item) => item._id === action.payload._id,
      );
      siteSettings[index] = action.payload;
      return {
        ...state,
        siteSettings: siteSettings,
      };
    }

    // BANNER
    case ADMIN_GET_BANNER:
      // console.log(action.payload);
      return {
        ...state,
        banners: [...action.payload],
      };
    case ADMIN_ADD_BANNER: {
      const banners = [...state.banners];
      banners.push(action.payload);
      return {
        ...state,
        banners: banners,
      };
    }
    case ADMIN_UPDATE_BANNER: {
      const banners = [...state.banners];
      let index = banners.findIndex((item) => item._id === action.payload._id);
      banners[index] = action.payload;
      return {
        ...state,
        banners: banners,
      };
    }
    case ADMIN_DELETE_BANNER: {
      const banners = [...state.banners];
      let index = banners.findIndex((item) => item._id === action.payload);
      banners.splice(index, 1);
      return {
        ...state,
        banners: banners,
      };
    }

    // STATS
    case ADMIN_GET_STAT:
      // console.log(action.payload);
      return {
        ...state,
        stats: [...action.payload],
      };
    case ADMIN_ADD_STAT: {
      const stats = [...state.stats];
      stats.push(action.payload);
      return {
        ...state,
        stats: stats,
      };
    }
    case ADMIN_UPDATE_STAT: {
      const stats = [...state.stats];
      let index = stats.findIndex((item) => item._id === action.payload._id);
      stats[index] = action.payload;
      return {
        ...state,
        stats: stats,
      };
    }
    case ADMIN_DELETE_STAT: {
      const stats = [...state.stats];
      let index = stats.findIndex((item) => item._id === action.payload);
      stats.splice(index, 1);
      return {
        ...state,
        stats: stats,
      };
    }

    // TRUSTWORTHY
    case ADMIN_GET_TRUSTWORTHY:
      // console.log(action.payload);
      return {
        ...state,
        trustworthy: [...action.payload],
      };
    case ADMIN_ADD_TRUSTWORTHY: {
      const trustworthy = [...state.trustworthy];
      trustworthy.push(action.payload);
      return {
        ...state,
        trustworthy: trustworthy,
      };
    }
    case ADMIN_UPDATE_TRUSTWORTHY: {
      const trustworthy = [...state.trustworthy];
      let index = trustworthy.findIndex(
        (item) => item._id === action.payload._id,
      );
      trustworthy[index] = action.payload;
      return {
        ...state,
        trustworthy: trustworthy,
      };
    }
    case ADMIN_DELETE_TRUSTWORTHY: {
      const trustworthy = [...state.trustworthy];
      let index = trustworthy.findIndex((item) => item._id === action.payload);
      trustworthy.splice(index, 1);
      return {
        ...state,
        trustworthy: trustworthy,
      };
    }

    //Contact us
    case ADMIN_GET_CONTACTUSALL:
      return {
        ...state,
        contactus: [...action.payload],
      };
    case ADMIN_DELETE_CONTACTUS: {
      const contactus = [...state.contactus];
      let index = contactus.findIndex((item) => item._id === action.payload);
      contactus.splice(index, 1);
      return {
        ...state,
        contactus: contactus,
      };
    }

    // NETWORKRANGE
    case ADMIN_GET_NETWORKRANGE:
      // console.log(action.payload);
      return {
        ...state,
        networkrange: [...action.payload],
      };
    case ADMIN_ADD_NETWORKRANGE: {
      const networkrange = [...state.networkrange];
      networkrange.push(action.payload);
      return {
        ...state,
        networkrange: networkrange,
      };
    }
    case ADMIN_UPDATE_NETWORKRANGE: {
      const networkrange = [...state.networkrange];
      let index = networkrange.findIndex(
        (item) => item._id === action.payload._id,
      );
      networkrange[index] = action.payload;
      return {
        ...state,
        networkrange: networkrange,
      };
    }
    case ADMIN_DELETE_NETWORKRANGE: {
      const networkrange = [...state.networkrange];
      let index = networkrange.findIndex((item) => item._id === action.payload);
      networkrange.splice(index, 1);
      return {
        ...state,
        networkrange: networkrange,
      };
    }

    // YYCSERVICES
    case ADMIN_GET_YYCSERVICES:
      // console.log(action.payload);
      return {
        ...state,
        yycservices: [...action.payload],
      };
    case ADMIN_ADD_YYCSERVICES: {
      const yycservices = [...state.yycservices];
      yycservices.push(action.payload);
      return {
        ...state,
        yycservices: yycservices,
      };
    }
    case ADMIN_UPDATE_YYCSERVICES: {
      const yycservices = [...state.yycservices];
      let index = yycservices.findIndex(
        (item) => item._id === action.payload._id,
      );
      yycservices[index] = action.payload;
      return {
        ...state,
        yycservices: yycservices,
      };
    }
    case ADMIN_DELETE_YYCSERVICES: {
      const yycservices = [...state.yycservices];
      let index = yycservices.findIndex((item) => item._id === action.payload);
      yycservices.splice(index, 1);
      return {
        ...state,
        yycservices: yycservices,
      };
    }

    //Order
    case ADMIN_GET_ORDERS:
      return {
        ...state,
        orders: [...action.payload],
      };
    case ADMIN_UPDATE_ORDER: {
      const orders = [...state.orders];
      let index = orders.findIndex((item) => item._id === action.payload._id);
      orders[index] = action.payload;
      return {
        ...state,
        orders: orders,
      };
    }
    case ADMIN_DELETE_ORDER: {
      const orders = [...state.orders];
      let index = orders.findIndex((item) => item._id === action.payload);
      orders.splice(index, 1);
      return {
        ...state,
        orders: orders,
      };
    }

    // onlineOrderService
    case ADMIN_GET_ONLINE_ORDER_SERVICES:
      // console.log(action.payload);
      return {
        ...state,
        onlineOrderService: [...action.payload],
      };
    case ADMIN_ADD_ONLINE_ORDER_SERVICES: {
      const onlineOrderService = [...state.onlineOrderService];
      onlineOrderService.push(action.payload);
      return {
        ...state,
        onlineOrderService: onlineOrderService,
      };
    }
    case ADMIN_UPDATE_ONLINE_ORDER_SERVICES: {
      const onlineOrderService = [...state.onlineOrderService];
      let index = onlineOrderService.findIndex(
        (item) => item._id === action.payload._id,
      );
      onlineOrderService[index] = action.payload;
      return {
        ...state,
        onlineOrderService: onlineOrderService,
      };
    }
    case ADMIN_DELETE_ONLINE_ORDER_SERVICES: {
      const onlineOrderService = [...state.onlineOrderService];
      let index = onlineOrderService.findIndex(
        (item) => item._id === action.payload,
      );
      onlineOrderService.splice(index, 1);
      return {
        ...state,
        onlineOrderService: onlineOrderService,
      };
    }

    // About Us
    case ADMIN_GET_ABOUT_US:
      // console.log(action.payload);
      return {
        ...state,
        aboutus: [...action.payload],
      };
    case ADMIN_ADD_ABOUT_US: {
      const aboutus = [...state.aboutus];
      aboutus.push(action.payload);
      return {
        ...state,
        aboutus: aboutus,
      };
    }
    case ADMIN_UPDATE_ABOUT_US: {
      const aboutus = [...state.aboutus];
      let index = aboutus.findIndex((item) => item._id === action.payload._id);
      aboutus[index] = action.payload;
      return {
        ...state,
        aboutus: aboutus,
      };
    }
    case ADMIN_DELETE_ABOUT_US: {
      const aboutus = [...state.aboutus];
      let index = aboutus.findIndex((item) => item._id === action.payload);
      aboutus.splice(index, 1);
      return {
        ...state,
        aboutus: aboutus,
      };
    }

    // Service Images
    case ADMIN_GET_SERVICE_IMAGE:
      // console.log(action.payload);
      return {
        ...state,
        serviceImages: [...action.payload],
      };
    case ADMIN_ADD_SERVICE_IMAGE: {
      const aboutus = [...state.serviceImages];
      aboutus.push(action.payload);
      return {
        ...state,
        serviceImages: aboutus,
      };
    }
    case ADMIN_UPDATE_SERVICE_IMAGE: {
      const aboutus = [...state.serviceImages];
      let index = aboutus.findIndex((item) => item._id === action.payload._id);
      aboutus[index] = action.payload;
      return {
        ...state,
        serviceImages: aboutus,
      };
    }
    case ADMIN_DELETE_SERVICE_IMAGE: {
      const aboutus = [...state.serviceImages];
      let index = aboutus.findIndex((item) => item._id === action.payload);
      aboutus.splice(index, 1);
      return {
        ...state,
        serviceImages: aboutus,
      };
    }

    // About Us
    case ADMIN_GET_LOCATION:
      // console.log(action.payload);
      return {
        ...state,
        location: [...action.payload],
      };
    case ADMIN_ADD_LOCATION: {
      const location = [...state.location];
      location.push(action.payload);
      return {
        ...state,
        location: location,
      };
    }
    case ADMIN_UPDATE_LOCATION: {
      const location = [...state.location];
      let index = location.findIndex((item) => item._id === action.payload._id);
      location[index] = action.payload;
      return {
        ...state,
        location: location,
      };
    }
    case ADMIN_DELETE_LOCATION: {
      const location = [...state.location];
      let index = location.findIndex((item) => item._id === action.payload);
      location.splice(index, 1);
      return {
        ...state,
        location: location,
      };
    }

    // PAGS
    case ADMIN_GET_PAGES:
      // console.log(action.payload);
      return {
        ...state,
        pages: [...action.payload],
      };
    case ADMIN_ADD_PAGES: {
      const pages = [...state.pages];
      pages.push(action.payload);
      return {
        ...state,
        pages: pages,
      };
    }
    case ADMIN_UPDATE_PAGES: {
      const pages = [...state.pages];
      let index = pages.findIndex((item) => item._id === action.payload._id);
      pages[index] = action.payload;
      return {
        ...state,
        pages: pages,
      };
    }
    case ADMIN_DELETE_PAGES: {
      const pages = [...state.pages];
      let index = pages.findIndex((item) => item._id === action.payload);
      pages.splice(index, 1);
      return {
        ...state,
        pages: pages,
      };
    }

    // PARTNERS
    case ADMIN_GET_PARTNERS:
      // console.log(action.payload);
      return {
        ...state,
        partners: [...action.payload],
      };
    case ADMIN_ADD_PARTNERS: {
      const partners = [...state.partners];
      partners.push(action.payload);
      return {
        ...state,
        partners: partners,
      };
    }
    case ADMIN_UPDATE_PARTNERS: {
      const partners = [...state.partners];
      let index = partners.findIndex((item) => item._id === action.payload._id);
      partners[index] = action.payload;
      return {
        ...state,
        partners: partners,
      };
    }
    case ADMIN_DELETE_PARTNERS: {
      const partners = [...state.partners];
      let index = partners.findIndex((item) => item._id === action.payload);
      partners.splice(index, 1);
      return {
        ...state,
        partners: partners,
      };
    }

    default:
      return state;
  }
}
