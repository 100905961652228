import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  InputLabel,
  Breadcrumbs,
  MenuItem,
  Typography,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import adminService from '../../services/adminService';
import { consoleError, getImageUrl } from '../../utils/helper';
import * as yup from 'yup';
import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from '../../components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import CustomFileUpload from '../../components/CustomFileUpload';
import CustomFileUploadServer from '../../components/CustomFileUploadServer';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import api from 'src/services/api';
// import FileUploadServer from '../../components/FileUploadServer';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    height: 400,
  },
}));
function EditNetworkRange() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [id, setId] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);

  const match = useMatch('/networkrange/:id/edit');
  useEffect(() => {
    if (match && match.params && match.params.id) {
      setId(match.params.id);
      setUpdate(true);
    }
  }, [match]);

  const networkrange = useSelector((state) => state.admin.networkrange);
  const data = networkrange.find((Item) => Item._id.toString() === id);

  useEffect(() => {
    getNetworkRange();
  }, []);

  const getNetworkRange = async () => {
    try {
      adminService.getNetworkRange(dispatch);
    } catch (error) {
      console.error(error);
    }
  };

  const addSiteSettingchema = yup.object().shape({
    title: yup
      .string()
      .required('title is required.')
      .min(2, 'title has min 2 chars.')
      .max(50, 'title has max 50 chars.'),
    subtitle: yup
      .string()
      .required('subtitle is required.')
      .min(2, 'subtitle has min 2 chars.')
      .max(500, 'subtitle has max 500 chars.'),
  });
  const updateSiteSettingchema = yup.object().shape({
    title: yup
      .string()
      .required('title is required.')
      .min(2, 'title has min 2 chars.')
      .max(50, 'title has max 50 chars.'),
    subtitle: yup
      .string()
      .required('subtitle is required.')
      .min(2, 'subtitle has min 2 chars.')
      .max(500, 'subtitle has max 500 chars.'),
  });

  const [tempImage, setTempImage] = useState();
  const uploadImageHandler = async (e, values, index, setFieldValue) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const image_New_Name = res.data;
      setTempImage(res.data);

      // values.image[index].name = image_New_Name;
      // console.log(values.meansOfTransport[index].image);

      // values.meansOfTransport[index].image = image_New_Name;

      setFieldValue(e.target.name, image_New_Name);

      // setFieldValue(e.target.name[index].image, values.image[index].name);
      console.log(values);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page className={classes.root} title={update ? 'Update data' : 'Add data'}>
      <Box
        display="flex"
        flexdirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h2">
              {update ? 'Update data' : 'Add data'}
            </Typography>
            <Box ml={2}>
              {/* <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate('/networkrange/add');
                }}
              >
                Add New data
              </Button> */}
            </Box>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/networkrange">
                Network range
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    ...data,
                  }
                : {
                    title: '',
                    subtitle: '',
                    meansOfTransport: [
                      {
                        image: '',
                        title: '',
                      },
                    ],
                  }
            }
            validationSchema={
              update ? updateSiteSettingchema : addSiteSettingchema
            }
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const { _id, title, subtitle, meansOfTransport } = values;
              const data = {
                title,
                subtitle,
                meansOfTransport,
              };
              if (update) {
                try {
                  let res = await adminService.updateNetworkRange(
                    _id,
                    data,
                    dispatch,
                  );
                  if (res && res.ok) {
                    setSuccessMsg(
                      res.message || 'NetworkRange updated successfully.',
                    );
                    setTimeout(() => {
                      window.location.reload();
                    }, 500);
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message ||
                      'Error updating NetworkRange. Please try again',
                  );
                }
              } else {
                try {
                  let res = await adminService.addNetworkRange(data, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(
                      res.message || 'NetworkRange added successfully.',
                    );
                    resetForm({});
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message ||
                      'Error adding NetworkRange. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Title"
                      margin="normal"
                      size="medium"
                      name="title"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Subtitle"
                      margin="normal"
                      size="medium"
                      name="subtitle"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Typography variant="h6">Means of transport</Typography>
                    </Box>
                    <FieldArray name="meansOfTransport">
                      {({ remove, push }) => (
                        <Box>
                          {values.meansOfTransport &&
                          values.meansOfTransport.length > 0 ? (
                            values.meansOfTransport.map(
                              (meansOfTransport, index) => (
                                <Grid container key={index} spacing={2}>
                                  <Grid item xs={12} md={12}>
                                    <input
                                      type="file"
                                      name={`meansOfTransport[${index}].image`}
                                      onChange={(e) =>
                                        uploadImageHandler(
                                          e,
                                          values,
                                          index,
                                          setFieldValue,
                                        )
                                      }
                                      accept="image/*"
                                    />
                                  </Grid>
                                  {values.meansOfTransport[index].image ? (
                                    <img
                                      src={`${config.apiUrl}${config.imageUrlLocal}/images/${values.meansOfTransport[index].image}`}
                                      alt="Service"
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                      }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                  <Grid item xs={5} md={4}>
                                    <Field
                                      component={TextField}
                                      label="Title"
                                      size="medium"
                                      name={`meansOfTransport.${index}.title`}
                                      fullWidth
                                      variant="outlined"
                                    />
                                  </Grid>

                                  <Grid
                                    xs={2}
                                    md={4}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={() => remove(index)}
                                      style={{
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                      }}
                                    >
                                      <RemoveIcon />
                                      Remove
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={() =>
                                        push({
                                          title: '',
                                          image: '',
                                        })
                                      }
                                    >
                                      <AddIcon />
                                      Add New
                                    </Button>
                                  </Grid>
                                </Grid>
                              ),
                            )
                          ) : (
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={12}>
                                <input
                                  type="file"
                                  name={`meansOfTransport[${
                                    values.meansOfTransport &&
                                    values.meansOfTransport.length
                                  }].image`}
                                  onChange={(e) =>
                                    uploadImageHandler(
                                      e,
                                      values,
                                      values.meansOfTransport.length,
                                    )
                                  }
                                  accept="image/*"
                                />
                              </Grid>
                              <Grid item xs={5} md={4}>
                                <Field
                                  component={TextField}
                                  label="Title"
                                  size="medium"
                                  name={`meansOfTransport.${
                                    values.meansOfTransport &&
                                    values.meansOfTransport.length
                                  }.title`}
                                  fullWidth
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid
                                xs={2}
                                md={4}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() =>
                                    remove(values.meansOfTransport.length)
                                  }
                                  style={{
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                  }}
                                >
                                  <RemoveIcon />
                                  Remove
                                </Button>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() =>
                                    push({
                                      title: '',
                                      image: '',
                                    })
                                  }
                                >
                                  <AddIcon />
                                  Add new
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </Box>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {(isSubmitting || uploadingImage) && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting || uploadingImage}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {update ? 'Update Data' : 'Add Data'}
                  </Button>
                </Box>
                <FormPreviewDev data={values} />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default EditNetworkRange;
