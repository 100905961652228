import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from 'src/components/Page';
import adminService from 'src/services/adminService';
import { consoleError, getImageUrl } from 'src/utils/helper';
import * as yup from 'yup';
import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from 'src/components/common/FormPreviewDev';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CustomFileUpload from 'src/components/CustomFileUpload';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function AddUser({ user = null }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (user) {
      setUpdate(true);
    }
  }, [user]);

  const addUserSchema = yup.object().shape({
    name: yup.string().required('Name is required.'),
    phone: yup.string().required('Phone is required.'),
    email: yup.string().required('Email is required.').email(),
    password: yup
      .string()
      .required('Password is required.')
      .min(8, 'Password is minimum 8 characters.'),
    avatar: yup
      .mixed()
      .test('fileType', 'Please select image (jpeg/png) file only.', (value) =>
        value ? SUPPORTED_FORMATS.includes(value ? value.type : null) : true,
      ),
    emailVerified: yup.boolean(),
    phoneVerified: yup.boolean(),
    role: yup
      .string()
      .required('Role is required')
      .oneOf(['superadmin', 'admin', 'delivery', 'user']),
    status: yup
      .string()
      .required('Status is required')
      .oneOf(['active', 'inactive', 'pending', 'banned']),
  });
  const updateUserSchema = yup.object().shape({
    name: yup.string().required('Name is required.'),
    phone: yup.string().required('Phone is required.'),
    email: yup.string().required('Email is required.').email(),
    newPassword: yup.boolean(),
    password: yup.mixed().when('newPassword', {
      is: true,
      then: yup
        .string()
        .required('Password is required.')
        .min(8, 'Password is minimum 8 characters.'),
    }),
    avatar: yup
      .mixed()
      .test('fileType', 'Please select image (jpeg/png) file only.', (value) =>
        value ? SUPPORTED_FORMATS.includes(value ? value.type : null) : true,
      ),
    emailVerified: yup.boolean(),
    phoneVerified: yup.boolean(),
    role: yup
      .string()
      .required('Role is required')
      .oneOf(['superadmin', 'admin', 'delivery', 'user']),
    status: yup
      .string()
      .required('Status is required')
      .oneOf(['active', 'inactive', 'pending', 'banned']),
  });
  const SUPPORTED_FORMATS = ['image/jpeg', 'image/png'];

  const handleClickShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Page className={classes.root} title={update ? 'Update User' : 'Add User'}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    _id: user._id,
                    name: user.name,
                    email: user.email,
                    phone: user.phone,
                    avatar: '',
                    currentAvatar: user.avatar,
                    emailVerified: user.emailVerified,
                    phoneVerified: user.phoneVerified,
                    role: user.role,
                    status: user.status,
                    newPassword: false,
                    password: '',
                  }
                : {
                    name: '',
                    email: '',
                    phone: '',
                    password: '',
                    avatar: '',
                    emailVerified: true,
                    phoneVerified: true,
                    role: 'user',
                    status: 'active',
                  }
            }
            validationSchema={update ? updateUserSchema : addUserSchema}
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const {
                _id,
                name,
                email,
                phone,
                avatar,
                newPassword,
                password,
                emailVerified,
                phoneVerified,
                role,
                status,
              } = values;
              const data = {
                name,
                email,
                phone,
                emailVerified,
                phoneVerified,
                role,
                status,
              };
              if (update) {
                if (newPassword) {
                  data.password = password;
                  data.newPassword = true;
                } else {
                  delete data.password;
                }
                try {
                  let res = await adminService.updateUser(_id, data, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'User updated successfully.');
                  }
                  //
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error updating user. Please try again.',
                  );
                }
              } else {
                try {
                  data.password = password;
                  let res = await adminService.addUser(data, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'User added successfully.');
                    resetForm({});
                  }
                  //
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error adding user. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Name"
                      margin="normal"
                      size="medium"
                      name="name"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Phone"
                      margin="normal"
                      size="medium"
                      name="phone"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Email"
                      margin="normal"
                      size="medium"
                      name="email"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  {update ? (
                    <Grid item xs={12} md={12}>
                      <InputLabel
                        htmlFor="newPassword"
                        className={classes.switchField}
                      >
                        New Password
                        <Field
                          component={Switch}
                          id="newPassword"
                          color="primary"
                          type="checkbox"
                          name="newPassword"
                        />
                      </InputLabel>
                    </Grid>
                  ) : null}
                  {(update && values.newPassword) || !update ? (
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        label="Password"
                        margin="normal"
                        size="medium"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={6}>
                    <InputLabel
                      htmlFor="emailVerified"
                      className={classes.switchField}
                    >
                      Email Verified
                      <Field
                        component={Switch}
                        id="emailVerified"
                        color="primary"
                        type="checkbox"
                        name="emailVerified"
                      />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel
                      htmlFor="phoneVerified"
                      className={classes.switchField}
                    >
                      Phone Verified
                      <Field
                        component={Switch}
                        id="phoneVerified"
                        color="primary"
                        type="checkbox"
                        name="phoneVerified"
                      />
                    </InputLabel>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Field
                      component={CustomFileUpload}
                      name="avatar"
                      label="Avatar"
                    />
                    {values.avatar || values.currentAvatar ? (
                      <Avatar
                        src={
                          update && !values.avatar
                            ? getImageUrl(values.currentAvatar)
                            : URL.createObjectURL(values.avatar)
                        }
                        variant="square"
                        className={classes.avatar}
                      />
                    ) : null}
                  </Grid> */}

                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        name="role"
                        type="text"
                        label="Role"
                        select={true}
                        variant="outlined"
                        fullWidth
                      >
                        <MenuItem value={'superadmin'}>Super Admin </MenuItem>
                        <MenuItem value={'admin'}>Admin</MenuItem>
                        <MenuItem value={'delivery'}>Delivery</MenuItem>
                        <MenuItem value={'user'}>User</MenuItem>
                      </Field>
                      {/* <ErrorMessage name="type" /> */}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        name="status"
                        type="text"
                        label="Status"
                        select={true}
                        variant="outlined"
                        fullWidth
                      >
                        <MenuItem value={'active'}>Active</MenuItem>
                        <MenuItem value={'inactive'}>Inactive</MenuItem>
                        {/* <MenuItem value={'pending'}>Pending</MenuItem> */}
                        <MenuItem value={'banned'}>Banned</MenuItem>
                      </Field>
                      {/* <ErrorMessage name="type" /> */}
                    </FormControl>
                  </Grid>
                </Grid>
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {isSubmitting && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {update ? 'Update User' : 'Add User'}
                  </Button>
                </Box>
                <FormPreviewDev data={values} />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default AddUser;
